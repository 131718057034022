import React, { useState, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { eventDestination, trackClickEvent } from 'diagnostics/calc-trackevents';
import * as exceptionSink from 'diagnostics/unhandled-exception-sink';
import newGuid from 'utilities/guid';
import './styles.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { iconEnum, iconSvgEnum } from '../icons';

type ButtonProps = {
  disabled: boolean,
  diagnosticId: string,
  onClick: Function<Promise> | Function,
};

export function BaseButton({
  icon,
  className,
  disabled,
  diagnosticId,
  onClick,
  children,
  ...rest
}): React.StatelessComponent<ButtonProps> {
  const [isBusy, setIsBusy] = useState(false);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const clickHandler = useCallback(
    async (event) => {
      try {
        if (isMounted.current) {
          setIsBusy(true);
          await onClick(event);
          trackClickEvent(diagnosticId);
        }
      } catch (e) {
        exceptionSink.send(e);
      } finally {
        if (isMounted.current) {
          setIsBusy(false);
        }
      }
    },
    [diagnosticId, onClick, setIsBusy]
  );

  return (
    <button
      className={classNames(className, icon)}
      disabled={isBusy || disabled}
      onClick={clickHandler}
      {...rest}
    >
      {children}
    </button>
  );
}

export function MaterialBaseButton({
  icon,
  className,
  disabled,
  diagnosticId,
  onClick,
  children,
  ...rest
}): React.StatelessComponent<ButtonProps> {
  const [isBusy, setIsBusy] = useState(false);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const clickHandler = useCallback(
    async (event) => {
      try {
        if (isMounted.current) {
          setIsBusy(true);
          await onClick(event);
          trackClickEvent(
            diagnosticId,
            {
              eventSource: diagnosticId,
            },
            {},
            eventDestination.ANALYSIS
          );
        }
      } catch (e) {
        exceptionSink.send(e);
      } finally {
        if (isMounted.current) {
          setIsBusy(false);
        }
      }
    },
    [diagnosticId, onClick, setIsBusy]
  );

  return (
    <button
      className={classNames(className)}
      disabled={isBusy || disabled}
      onClick={clickHandler}
      {...rest}
    >
      {icon}
    </button>
  );
}

export function PrimaryButton({ className, ...rest }) {
  const classes = classNames('primary-button', className);
  return <BaseButton {...rest} className={classes} />;
}

export function SecondaryButton({ className, ...rest }) {
  const classes = classNames('secondary-button', className);
  return <BaseButton {...rest} className={classes} />;
}

export function IconButton({ icon, className, ...rest }) {
  let IconComponent = null;
  let classes = classNames('icon-only-button', className);

  if (iconEnum[icon]) {
    classes = classNames(classes, iconEnum[icon]);
  } else if (iconSvgEnum[icon]) {
    IconComponent = iconSvgEnum[icon];
  }

  return (
    <BaseButton icon={icon} {...rest} className={classes}>
      {IconComponent && <IconComponent />}
    </BaseButton>
  );
}
export function MaterialIconButton({ className, ...rest }) {
  const classes = classNames('material-icons', className);
  return <MaterialBaseButton {...rest} className={classes} />;
}

export const IconButtonToolTip = (props) => {
  const { className, disabled, place, onClick, text, diagnosticId, positionStrategy } = props;
  let guid = newGuid();
  const classes = classNames(className);

  return (
    <>
      <div data-tooltip-id={`tooltip-${guid}`}>
        <IconButton
          icon={props.icon}
          disabled={disabled}
          onClick={onClick}
          className={classes}
          diagnosticId={diagnosticId}
        />
      </div>
      {!disabled && (
        <ReactTooltip
          place={place || 'bottom'}
          id={`tooltip-${guid}`}
          className="ve-tooltip-default"
          noArrow
          positionStrategy={positionStrategy}
        >
          {text}
        </ReactTooltip>
      )}
    </>
  );
};
