import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Menu, MenuItem } from 'components/menu';
import { iconEnum } from 'components/icons';
import classNames from 'classnames';
import { IconButtonToolTip } from 'components/button';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import { OrderType } from 'utilities/array-object-sort';
import DragAndDropItem from 'components/drag-and-drop/drag-and-drop-item';
import './styles.scss';
import { bindActionCreators } from 'redux';
import { updateWorksheetSortOrderToWorkbook, worksheetsReordered } from 'actions/workbook-manager';
import { connect } from 'react-redux';
import { selector } from './selector';
import userContext from '../../../../user-context';
import { saveWorkbook } from 'api/clients/workbook';

export const TabsDropdown = forwardRef(
  (
    {
      isDisabled,
      activeWorksheetId,
      onClick,
      sortOrderType,
      workbook,
      worksheetsReordered,
      updateWorksheetSortOrderToWorkbook,
      worksheetsSortedForDisplay,
    },
    ref
  ) => {
    const [currentSortOrderType, setCurrentSortOrderType] = useState(
      sortOrderType || OrderType.NONE
    );
    const [draggingItemId, setDraggingItemId] = useState(null);

    const activeWorksheet = worksheetsSortedForDisplay.find(
      (item) => item.id === activeWorksheetId
    );
    const workbookId = workbook.id;
    const activeIndex = worksheetsSortedForDisplay.findIndex(
      (item) => item.id === activeWorksheetId
    );

    useEffect(() => {
      setCurrentSortOrderType(sortOrderType);
    }, [sortOrderType]);

    const getEventName = (sortOrderType: OrderType) => {
      let eventName = undefined;
      switch (sortOrderType) {
        case OrderType.NONE:
          eventName = 'Worksheet sorted by default order';
          break;
        case OrderType.ALPHA_ASC:
          eventName = 'Worksheet sorted by alphabetically ascending';
          break;
        default:
          eventName = 'Worksheet sorted by default order';
      }
      return eventName;
    };

    const handleSortTypeChange = (orderType) => {
      updateWorksheetSortOrderToWorkbook(workbookId, orderType);
      setCurrentSortOrderType(orderType);
    };

    useImperativeHandle(ref, () => ({
      handleSortTypeChange,
    }));
    const handleDragStart = (dragInfo) => {
      setDraggingItemId(dragInfo.draggableId);
    };
    const handleDragEnd = async (dragInfo) => {
      setDraggingItemId(null);
      const { source, destination } = dragInfo;
      if (!destination || source.index === destination.index) {
        return;
      }
      if (currentSortOrderType !== OrderType.CUSTOM_ORDER) {
        setCurrentSortOrderType(OrderType.CUSTOM_ORDER);
        await updateWorksheetSortOrderToWorkbook(workbookId, OrderType.CUSTOM_ORDER);
      }

      worksheetsReordered({
        sourceIndex: dragInfo.source.index,
        destinationIndex: dragInfo.destination.index,
        workbookId: workbookId,
      });
    };

    const getListOfSortedWorksheets = () => {
      return worksheetsSortedForDisplay.map((ow, index) => (
        <MenuItem
          key={ow.id}
          className={getCssClassForWorksheet(ow.id, activeWorksheetId)}
          onClick={() => onClick(ow.id, index)}
        >
          <DragAndDropItem
            className={classNames('menu-item-draggable', {
              'draggable-visible': draggingItemId === ow.id,
            })}
            key={ow.id}
            draggableId={ow.id}
            index={index}
          >
            <div
              className={classNames('menu-item-drag-icon has-icon icon--grip', {
                'draggable-visible': draggingItemId === ow.id,
              })}
            />
            <span className="menu-item-title">{ow.name}</span>
          </DragAndDropItem>
        </MenuItem>
      ));
    };

    const isSelectedOrder = (order: OrderType) => {
      return currentSortOrderType === order;
    };

    const menuClosed = async () => {
      // track sorting events
      const diagnosticId = 'Worksheet Sorting';
      trackEvent(
        diagnosticId,
        getEventName(currentSortOrderType),
        {
          diagnosticId: diagnosticId,
        },
        {},
        eventDestination.ANALYSIS
      );
      // save sort order
      if (workbook.userId === userContext.systemUserId) {
        await saveWorkbook({
          ...workbook,
          sortOrderType: currentSortOrderType,
          worksheetSortOrderByIds: workbook.worksheetSortOrderByIds,
        });
      }
    };

    let selectedTab = activeWorksheet && activeWorksheetId && (
      <MenuItem
        key={activeWorksheetId}
        className="tabs-dropdown__tab--active tabs-dropdown-header"
        onClick={() => onClick(activeWorksheetId, activeIndex)}
      >
        <div className="sorting-container">
          <div className="sorting-row">
            <div className="sorting-row-title">{activeWorksheet.name}</div>
            <div className="sorting-row-icon-section">
              <IconButtonToolTip
                className={classNames('sorting-row-icon', {
                  'sorting-row-icon-active': isSelectedOrder(OrderType.CUSTOM_ORDER),
                })}
                icon="CustomSort"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSortTypeChange(OrderType.CUSTOM_ORDER);
                }}
                text="Custom Worksheet Order"
                diagnosticId="TabsDropDown/CustomOrder"
                positionStrategy="fixed"
              />
              <IconButtonToolTip
                className={classNames('sorting-row-icon', {
                  'sorting-row-icon-active': isSelectedOrder(OrderType.ALPHA_ASC),
                })}
                icon="SortByAlpha"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSortTypeChange(OrderType.ALPHA_ASC);
                }}
                text="Sort Alphabetically"
                diagnosticId="TabsDropDown/SortByAscending"
                positionStrategy="fixed"
              />
              <IconButtonToolTip
                className={classNames('sorting-row-icon', {
                  'sorting-row-icon-active': isSelectedOrder(OrderType.NONE),
                })}
                icon="SortByDate"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSortTypeChange(OrderType.NONE);
                }}
                text="Sort by Date"
                diagnosticId="TabsDropDown/SortByDate"
                positionStrategy="fixed"
              />
            </div>
          </div>
        </div>
      </MenuItem>
    );

    return (
      <Menu
        disabled={isDisabled}
        icon={iconEnum.ChevronDown}
        promotedItem={selectedTab}
        menuClosed={menuClosed}
        handleDragStart={handleDragStart}
        handleDragEnd={handleDragEnd}
        workbookId={workbookId}
      >
        {getListOfSortedWorksheets()}
      </Menu>
    );
  }
);

const getCssClassForWorksheet = (worksheetId, activeWorksheetId) =>
  worksheetId === activeWorksheetId ? 'tabs-dropdown__tab--active' : null;

const mapStateToProps = selector;
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      worksheetsReordered,
      updateWorksheetSortOrderToWorkbook,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsDropdown);
