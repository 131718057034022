import React, { useState } from 'react';
import { isDischargePort, isLoadOrDischargePort, isLoadPort } from 'constants/enums/voyage-leg';
import classNames from 'classnames';
import { isFeatureEnabled } from 'config/feature-control';
import LegContainer from 'modules/voyage/components/leg-container';
import PreviousPortOfCall from 'modules/voyage/components/previous-port-of-call';
import VoyageHeader from 'modules/voyage/components/voyage-header';
import NextPortOfCall from 'modules/voyage/components/next-port-of-call';
import type { OpenPosition } from 'modules/vessel/vessel-detail/types';
import {
  getEuaLabel,
  getIndexOfLastLoadOrDischargePort,
  getPortsTotalLoadAndDischarge,
} from '../../helpers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selector } from 'modules/voyage/selectors';
import './styles.scss';
import DragAndDropContainer from 'components/drag-and-drop/drag-and-drop-container';

interface VoyageReduxProps {
  activeVessel: IVessel;
  voyage: IVoyage;
  cargoCodes: any[];
  calculationStatus: CalculationStatus;
  worksheetInvariantProps: IWorksheetInvariantProps;
}

interface VoyageProps extends VoyageReduxProps {
  handleOpenPositionPrevPortOfCallIsInEeaUpdated: (value: boolean) => void;
  renderVoyageLeg: (
    port: any,
    index: number,
    cargoCodes: any[],
    accumulatedCargo: number,
    totalLoad: number,
    totalDischarge: number,
    lastLoadOrDischargePortIndex: number,
    calculationStatus: CalculationStatus
  ) => any;
  handleNextPortOfCallIsInEeaUpdated: (value: boolean) => void;
  handleOpenPositionUpdated: (newPosition: OpenPosition) => void;
  appendNewVoyageEntry: () => void;
  handleDraftUnitChanged: (newDraftUnit: string) => void;
  showNextPortOfCall: boolean;
  handleDragEnd: (dragInfo: any) => void;
}

const VoyageGraph = ({
  activeVessel,
  voyage,
  handleOpenPositionPrevPortOfCallIsInEeaUpdated,
  renderVoyageLeg,
  handleNextPortOfCallIsInEeaUpdated,
  cargoCodes,
  calculationStatus,
  worksheetInvariantProps,
  handleOpenPositionUpdated,
  appendNewVoyageEntry,
  handleDraftUnitChanged,
  showNextPortOfCall,
  handleDragEnd,
}: VoyageProps) => {
  const [accumulatedCargo, setAccumulatedCargo] = useState(0);
  const lastLoadOrDischargePortIndex = getIndexOfLastLoadOrDischargePort(voyage.legs);

  const updateAccumulatedCargo = (port) => {
    if (isDischargePort(port)) {
      setAccumulatedCargo(accumulatedCargo - port.cargoQuantity);
    } else if (isLoadPort(port)) {
      setAccumulatedCargo(accumulatedCargo + port.cargoQuantity);
    }
  };

  const { totalLoad, totalDischarge } = getPortsTotalLoadAndDischarge(voyage.legs);

  const openPosition = activeVessel?.openPosition;

  const voyageLegs = voyage.legs;
  const prevPortOfCallIsInEea = activeVessel?.openPosition?.prevPortOfCallIsInEea;
  const nextPortOfCallIsInEea = activeVessel?.nextPortOfCallIsInEea;
  const isOpenLocationSet = !!openPosition?.name;

  return (
    <div
      className={classNames({
        'voyage-container': isFeatureEnabled('newVoyageTable'),
      })}
    >
      {isFeatureEnabled('newVoyageTable') && (
        <>
          <LegContainer
            euaLabel={
              <div style={{ top: 0 }} className="eua-label">
                EUA
              </div>
            }
          >
            <div className="port-of-call-top-container">
              <PreviousPortOfCall
                handleOpenPositionPrevPortOfCallIsInEeaUpdated={
                  handleOpenPositionPrevPortOfCallIsInEeaUpdated
                }
                openPosition={openPosition}
                oldView={false}
              />
            </div>
          </LegContainer>
          <LegContainer
            dotClassName={classNames('dot', !isOpenLocationSet ? 'dimmed-dot' : 'empty-dot')}
          >
            <div className={classNames('worksheet-section__property', 'open-location-container')}>
              <label className="worksheet-section__property-label">
                Open
                {!isOpenLocationSet ? (
                  ' Location not determined'
                ) : (
                  <>
                    &nbsp;at <strong>{openPosition?.name}</strong>
                  </>
                )}
              </label>
            </div>
          </LegContainer>
        </>
      )}
      <div className="voyage-data">
        <VoyageHeader
          addVoyageLeg={appendNewVoyageEntry}
          draftUnit={voyage.draftUnit}
          onDraftUnitChange={handleDraftUnitChanged}
          shouldAutoCalculateIntake={voyage.shouldAutoCalculateIntake}
          worksheetInvariantProps={worksheetInvariantProps}
        />
        <div style={{ display: 'flex' }}>
          {isFeatureEnabled('newVoyageTable') && (
            <div>
              {voyage.legs.map((port, index) => {
                return (
                  <div key={port.id} className="main-voyage-leg">
                    <div
                      className={classNames(
                        'main-timeline',
                        {
                          'dotted-timeline': openPosition?.name === '' && index === 0,
                        },
                        {
                          'main-timeline--first': openPosition?.name !== '' && index === 0,
                        }
                      )}
                    >
                      <div
                        className={classNames('dot', {
                          'empty-dot': !isLoadOrDischargePort(port.type),
                        })}
                      />
                      <div
                        className={classNames('eua-label', {
                          'eua-label--first': index === 0,
                        })}
                      >
                        {getEuaLabel(
                          index,
                          voyageLegs,
                          prevPortOfCallIsInEea,
                          nextPortOfCallIsInEea
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <DragAndDropContainer onDragEnd={handleDragEnd}>
            {voyage.legs.map((port, index) => {
              const voyageLeg = renderVoyageLeg(
                port,
                index,
                cargoCodes,
                accumulatedCargo,
                totalLoad,
                totalDischarge,
                lastLoadOrDischargePortIndex,
                calculationStatus
              );

              updateAccumulatedCargo(port);

              return voyageLeg;
            })}
          </DragAndDropContainer>
        </div>
      </div>

      {isFeatureEnabled('newVoyageTable') && showNextPortOfCall && (
        <LegContainer>
          <div className="port-of-call-bottom-container">
            <NextPortOfCall
              activeVessel={activeVessel}
              handleNextPortOfCallIsInEeaUpdated={handleNextPortOfCallIsInEeaUpdated}
              oldView={false}
            />
          </div>
        </LegContainer>
      )}
    </div>
  );
};

const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VoyageGraph);
