import {
  SET_WORKBOOK,
  WORKBOOK_SET_ALL,
  WORKBOOK_REMOVE_WORKSHEET,
  WORKBOOK_REMOVE,
  SET_ACTIVE_WORKSHEET_ID,
  SET_WORKBOOK_SPEED_AND_CONSUMPTIONS_MODE,
  UPDATE_WORKSHEET_SORT_ORDER_TO_WORKBOOK,
  WORKSHEETS_REORDERED,
  UPDATE_CURRENT_WORKSHEET_SORT_ORDER,
} from 'constants/action-types/workbook';
import { OrderType, sortArrayObjectsBy } from 'utilities/array-object-sort';
import { ADD_WORKSHEET_TO_WORKBOOK } from 'constants/action-types/workbook-manager';

const initialState = {};

export const workbooksReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_WORKSHEET_SORT_ORDER_TO_WORKBOOK:
      return {
        ...state,
        [action.payload.workbookId]: {
          ...state[action.payload.workbookId],
          sortOrderType: action.payload.sortOrderType,
        },
      };
    case SET_WORKBOOK_SPEED_AND_CONSUMPTIONS_MODE:
      return {
        ...state,
        [action.payload.workbookId]: {
          ...state[action.payload.workbookId],
          speedAndConsumptionsMode: action.payload.speedAndConsumptionsMode,
        },
      };
    case SET_WORKBOOK:
      const {
        worksheets /* delibrately ignored to prevent accidental access; worksheets associated with this workbook can found within `worksheetsById` */,
        ...workbookViewModel
      } = action.payload;

      const worksheetsSortedForDisplay = sortArrayObjectsBy(
        worksheets,
        workbookViewModel.sortOrderType,
        'name',
        workbookViewModel.worksheetSortOrderByIds
      );

      if (
        !state[action.payload.id]?.worksheetSortOrderByIds ||
        !state[action.payload.id]?.worksheetSortOrderByIds.length
      ) {
        workbookViewModel.worksheetSortOrderByIds = worksheets.map(
          (worksheet) => worksheet?.worksheetId
        );
        workbookViewModel.currentSortOrder = worksheets.map((worksheet) => worksheet?.worksheetId);
      } else {
        workbookViewModel.worksheetSortOrderByIds =
          state[action.payload.id].worksheetSortOrderByIds;
        workbookViewModel.currentSortOrder = worksheetsSortedForDisplay.map(
          (worksheet) => worksheet?.worksheetId
        );
      }

      if (state[action.payload.id] && state[action.payload.id].sortOrderType) {
        workbookViewModel.sortOrderType = state[action.payload.id].sortOrderType;
      } else {
        workbookViewModel.sortOrderType = OrderType.NONE;
      }

      return {
        ...state,
        [action.payload.id]: {
          ...workbookViewModel,
        },
      };
    case UPDATE_CURRENT_WORKSHEET_SORT_ORDER: {
      const { worksheetIds, workbookId } = action.payload;

      return {
        ...state,
        [workbookId]: {
          ...state[workbookId],
          currentSortOrder: worksheetIds,
        },
      };
    }
    case WORKSHEETS_REORDERED: {
      const { sourceIndex, destinationIndex, workbookId } = action.payload;
      const order = state[workbookId]?.currentSortOrder;

      if (
        sourceIndex < 0 ||
        destinationIndex < 0 ||
        sourceIndex >= order.length ||
        destinationIndex >= order.length
      ) {
        return state;
      }
      const [movedId] = order.splice(sourceIndex, 1);
      order.splice(destinationIndex, 0, movedId);

      return {
        ...state,
        [workbookId]: {
          ...state[workbookId],
          worksheetSortOrderByIds: order,
        },
      };
    }
    case ADD_WORKSHEET_TO_WORKBOOK: {
      const { workbookId, worksheetId } = action.payload;

      return {
        ...state,
        [workbookId]: {
          ...state[workbookId],
          worksheetSortOrderByIds: [...state[workbookId].worksheetSortOrderByIds, worksheetId],
          currentSortOrder: [...state[workbookId].currentSortOrder, worksheetId],
        },
      };
    }
    case WORKBOOK_SET_ALL:
      return action.payload.reduce((map, workbook) => {
        const {
          worksheets /* delibrately ignored to prevent accidental access; worksheets associated with this workbook can found within `worksheetsById` */,
          ...workbookViewModel
        } = workbook;
        return {
          ...map,
          [workbook.id]: {
            ...workbookViewModel,
          },
        };
      }, state);
    case SET_ACTIVE_WORKSHEET_ID:
      return {
        ...state,
        [action.payload.workbookId]: {
          ...state[action.payload.workbookId],
          lastViewedWorksheetId: action.payload.worksheetId,
        },
      };
    case WORKBOOK_REMOVE_WORKSHEET:
      return removeWorksheetFromWorkbook(state, action.payload);
    case WORKBOOK_REMOVE:
      return removeWorkbook(state, action.payload);
    default:
      return state;
  }
};

const removeWorksheetFromWorkbook = (state, payload) => {
  const { worksheetId, workbookId, nextWorksheetId } = payload;
  const workbook = state[workbookId];

  if (!workbook) {
    return state;
  }

  return {
    ...state,
    [workbookId]: {
      ...workbook,
      lastViewedWorksheetId: nextWorksheetId,
      worksheetSortOrderByIds: state[workbookId].worksheetSortOrderByIds.filter(
        (id) => id !== worksheetId
      ),
      currentSortOrder: state[workbookId].currentSortOrder.filter((id) => id !== worksheetId),
    },
  };
};

const removeWorkbook = (state, workbookId) => {
  if (!state[workbookId]) {
    return state;
  }
  delete state[workbookId];
  return { ...state };
};
