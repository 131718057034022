export const SALT = { key: 1, label: 'Salt', value: 1.0 };
export const BRACKISH = { key: 2, label: 'Brackish', value: 1.011 };
export const FRESH = { key: 3, label: 'Fresh', value: 1.025 };
export const TROPICAL = { key: 4, label: 'Tropical', value: 1.004 };
export const TROPICAL_FRESH = { key: 5, label: 'Tropical Fresh', value: 1.03 };

export const getSalinityTypeById = (typeId) => {
  switch (typeId) {
    case SALT.key:
      return SALT;
    case BRACKISH.key:
      return BRACKISH;
    case FRESH.key:
      return FRESH;
    case TROPICAL.key:
      return TROPICAL;
    case TROPICAL_FRESH.key:
      return TROPICAL_FRESH;
    default:
      return {};
  }
};

export const SalinityTypes = [SALT, TROPICAL, BRACKISH, FRESH, TROPICAL_FRESH];
