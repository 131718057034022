import { veApi } from 'api';
import { enrichWithGettersFromClass } from 'utilities/object-mixin-utils';
import WorkbookComputedProps from './workbook-computed-props';
import VError from 'verror';

const baseResourceUrl = '/workbook/';

export async function getWorkbookById(workbookId) {
  let workbook;
  try {
    const response = await veApi.get(baseResourceUrl + workbookId);
    workbook = response.data;
  } catch (error) {
    if (error.response.status === 404) {
      throw new VError(
        {
          cause: error,
          name: 'ERROR_WORKBOOK_NOT_FOUND',
          info: {
            workbookId,
          },
        },
        'Workbook not found'
      );
    } else throw error;
  }

  hydrateWorkbook(workbook);
  return workbook;
}

export async function getMyWorkbooks() {
  const response = await veApi.get(baseResourceUrl);
  return getWorkbooksFromListResponse(response);
}

export async function searchWorkbooks({ userId }) {
  const response = await veApi.get(baseResourceUrl + `?userId=${userId}`);
  return getWorkbooksFromListResponse(response);
}

export async function createWorkbook({ marketSegmentId }): WorkbookId {
  const createWorkBookResponse = await veApi.post(`/workbook`);
  const workbookId = createWorkBookResponse.data;
  return workbookId;
}

export async function saveWorkbook(workbook) {
  await veApi.put(`/workbook/${workbook.id}`, {
    workbook: workbook,
  });
}

function getWorkbooksFromListResponse({ data: { workbooks } }) {
  for (const workbook of workbooks) {
    hydrateWorkbook(workbook);
  }
  return workbooks;
}

function hydrateWorkbook(workbook) {
  workbook.updatedDate = new Date(workbook.updatedDate);
  enrichWithGettersFromClass(workbook, WorkbookComputedProps);
}
