import { ADD_WORKSHEET_TO_WORKBOOK } from 'constants/action-types/workbook-manager';
import {
  UPDATE_WORKSHEET_SORT_ORDER_TO_WORKBOOK,
  WORKSHEETS_REORDERED,
  UPDATE_CURRENT_WORKSHEET_SORT_ORDER,
} from 'constants/action-types/workbook';
import { buildAction } from 'utilities/redux';

export const addWorksheetToWorkbook = (workbookId, worksheetId, worksheetName) =>
  buildAction(ADD_WORKSHEET_TO_WORKBOOK, {
    workbookId,
    worksheetId,
    worksheetName,
  });

export const updateWorksheetSortOrderToWorkbook = (workbookId, sortOrderType) =>
  buildAction(UPDATE_WORKSHEET_SORT_ORDER_TO_WORKBOOK, {
    workbookId,
    sortOrderType,
  });

export const worksheetsReordered = ({ sourceIndex, destinationIndex, workbookId }) =>
  buildAction(WORKSHEETS_REORDERED, {
    sourceIndex,
    destinationIndex,
    workbookId,
  });

export const updateCurrentWorksheetSortOrder = (worksheetIds, workbookId) =>
  buildAction(UPDATE_CURRENT_WORKSHEET_SORT_ORDER, {
    worksheetIds,
    workbookId,
  });
