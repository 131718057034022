import * as SandCType from 'constants/enums/speed-and-con-types';

import {
  VOYAGE_AVOID_SECA_ZONES_CHANGED,
  VOYAGE_CARGO_QUANTITY_CHANGED,
  VOYAGE_CARGO_QUANTITY_CHANGED_ALL_LEGS,
  VOYAGE_DELAY_CHANGED,
  VOYAGE_DELAY_UNIT_CHANGED,
  VOYAGE_DELAY_CHANGED_ALL_LEGS,
  VOYAGE_DRAFT_CHANGED,
  VOYAGE_DRAFT_UNIT_CHANGED,
  VOYAGE_GEAR_CHANGED,
  VOYAGE_LEG_MOVED,
  VOYAGE_LOAD_DISCHARGE_RATE_CHANGED,
  VOYAGE_LOAD_DISCHARGE_RATE_UNIT_CHANGED,
  VOYAGE_LOCATION_ADDED,
  VOYAGE_LOCATION_CHANGED,
  VOYAGE_LOCATION_DELETED,
  VOYAGE_PORT_COST_CHANGED,
  VOYAGE_IS_IN_SECA_CHANGED,
  VOYAGE_IS_IN_EEA_CHANGED,
  VOYAGE_ROUTE_VARIANTS_SET,
  VOYAGE_SALINITY_CHANGED,
  VOYAGE_TURN_TIME_CHANGED,
  VOYAGE_TURN_TIME_UNIT_CHANGED,
  VOYAGE_TYPE_CHANGED,
  VOYAGE_WEATHER_FACTOR_CHANGED,
  VOYAGE_SET_PORTS_IN_SECA,
  VOYAGE_WORKING_DAY_MULTIPLIER_CHANGED,
  VOYAGE_TOGGLE_AUTO_INTAKE_CALC,
  VOYAGE_CONSUMPTION_OVERRIDE_QTY_CHANGED,
  VOYAGE_CONSUMPTION_OVERRIDE_QTY_SET,
  VOYAGE_LEGS_LOADED,
  VOYAGE_TYPE_CHANGED_UPDATE_DELAY_ALL_LEGS,
} from 'constants/action-types/worksheet/voyage';
import { LOAD_WORKSHEET } from 'constants/action-types/workbook';
import { DISCHARGE, LOAD } from 'constants/enums/voyage-leg';
import { METRIC_TONNES_PER_HOUR } from 'modules/voyage/load-discharge-rate-unit-types';
import { marketSegmentIds } from 'constants/market-segments';
import isNil from 'lodash/isNil';
import findIndex from 'lodash/findIndex';
import { singleOrThrow } from 'utilities/iterable';
import { operationalRestrictionIds } from 'constants/operational-restrictions';
import { METERS } from 'constants/enums/draft-units';
import { voyageEntriesReducer } from './voyage-entries';
import { VoyageLegBuilder, mapRouteRichInfoToRouteViewModel } from './voyage-leg-builder';
import { LOADING } from 'modules/voyage/gear-types';
import { SPEEDCONS_RESET } from '../../../constants/action-types/worksheet/speed-and-consumptions';

const initialState: IVoyageViewModel = {
  draftUnit: METERS.key,
  avoidSecaZones: true,
  shouldAutoCalculateIntake: false,
  legs: [new VoyageLegBuilder(LOAD.key).build(), new VoyageLegBuilder(DISCHARGE.key).build()],
};

export default (state: IVoyageViewModel = initialState, action: Action): IVoyageViewModel => {
  // TODO - the `reducers/worksheet/voyage/index` has but a handful of properties (`draftUnit` and `avoidSecaZones` at the time of writing), and all the rest pertain to its `legs` member (#TODOGroupLegsActionsToVoyageEntriesReducer) and the individual items #TODOGroupLegActionsToVoyageEntryReducer 'at location activities' concerns. TODO: It would be good to start separating all these concerns in the reducers' bounded context too.
  const newLegsOrSame = voyageEntriesReducer(state.legs, action);
  if (newLegsOrSame !== state.legs)
    state = {
      ...state,
      legs: newLegsOrSame,
    };
  const loadAndDischargeLegsCount = state.legs?.filter(
    (leg) => leg.type === LOAD.key || leg.type === DISCHARGE.key
  ).length;
  switch (action.type) {
    case VOYAGE_SET_PORTS_IN_SECA:
      const { locationIds, inSeca } = action.payload;
      return {
        ...state,
        legs: state.legs.map((leg) => {
          if (locationIds.includes(leg.locationId)) {
            let portOperationalRestrictionIds = new Set(leg.portOperationalRestrictionIds || []);

            if (inSeca) {
              portOperationalRestrictionIds.add(operationalRestrictionIds.seca);
            } else {
              portOperationalRestrictionIds.delete(operationalRestrictionIds.seca);
            }

            return {
              ...leg,
              isInSeca: inSeca,
              portOperationalRestrictionIds,
            };
          }
          return leg;
        }),
      };
    case LOAD_WORKSHEET:
      return getVoyageViewModelFromWorksheetDto(action.payload);
    case VOYAGE_TYPE_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                ...action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_CONSUMPTION_OVERRIDE_QTY_SET:
      return setConsumptionOverrideQty(state, action.payload);
    case SPEEDCONS_RESET:
      return resetConsumptionOverrideQty(state, action);
    case VOYAGE_CONSUMPTION_OVERRIDE_QTY_CHANGED:
      return updateConsumptionOverrideQty(state, action.payload);
    case VOYAGE_LOCATION_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          // See #FixLegsMisnamedAsPorts
          leg.id === action.portId
            ? {
                ...leg,
                locationId: action.payload.locationId,
                name: action.payload.locationName,
                zone: action.payload.zone || '',
                country: action.payload.country || '',
                isInEea: action.payload.isInEea,
                isInSeca: action.payload.isInSeca,
                isInSecaOverridden: false,
                longitude: action.payload.longitude || null,
                latitude: action.payload.latitude || null,
                portOperationalRestrictionIds: action.payload.isInSeca
                  ? new Set([operationalRestrictionIds.seca])
                  : new Set(),
              }
            : leg
        ),
      };
    case VOYAGE_GEAR_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                gear: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_LEG_MOVED:
      let legsClone = [...state.legs];
      let leg = legsClone[action.sourceIndex];
      legsClone.splice(action.sourceIndex, 1);
      legsClone.splice(action.destinationIndex, 0, leg);
      return {
        ...state,
        legs: legsClone,
      };
    case VOYAGE_DRAFT_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                draft: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_DRAFT_UNIT_CHANGED:
      return {
        ...state,
        draftUnit: action.draftUnit,
        legs: state.legs.map((leg) => ({
          ...leg,
          draft: action.payload[leg.id],
        })),
      };
    case VOYAGE_SALINITY_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                salinity: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_CARGO_QUANTITY_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                cargoQuantity: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_CARGO_QUANTITY_CHANGED_ALL_LEGS:
      return {
        ...state,
        legs: state.legs.map((leg) => ({
          ...leg,
          cargoQuantity: action.payload,
        })),
      };
    case VOYAGE_LOAD_DISCHARGE_RATE_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                loadDischargeRate: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_LOAD_DISCHARGE_RATE_UNIT_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                rateUnit: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_WORKING_DAY_MULTIPLIER_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                workingDayType: action.payload.id,
                workingDayMultiplier: action.payload.factor,
              }
            : leg
        ),
      };
    case VOYAGE_DELAY_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                delay: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_DELAY_UNIT_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                delayUnit: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_DELAY_CHANGED_ALL_LEGS:
      let delaysPerLeg = 0;
      if (loadAndDischargeLegsCount !== 0 && action.payload.delay !== 0) {
        delaysPerLeg = action.payload.delay / loadAndDischargeLegsCount;
      }
      return {
        ...state,
        legs: state.legs.map((leg) => {
          if (leg.type === LOAD.key || leg.type === DISCHARGE.key) {
            return {
              ...leg,
              delay: delaysPerLeg,
              delayUnit: action.payload.delayUnit,
            };
          } else {
            return {
              ...leg,
              delay: 0,
            };
          }
        }),
        totalWaitingDays: action.payload.delay,
        delayUnit: action.payload.delayUnit,
      };
    case VOYAGE_TYPE_CHANGED_UPDATE_DELAY_ALL_LEGS:
      const delay = state.totalWaitingDays;
      const delayPerLeg = delay / loadAndDischargeLegsCount;
      return {
        ...state,
        legs: state.legs.map((leg) => {
          if (leg.type === LOAD.key || leg.type === DISCHARGE.key) {
            return {
              ...leg,
              delay: delayPerLeg,
              delayUnit: 2,
            };
          } else {
            return {
              ...leg,
              delay: 0,
            };
          }
        }),
      };
    case VOYAGE_TURN_TIME_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                turnAroundTime: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_TURN_TIME_UNIT_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                turnAroundTimeUnit: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_WEATHER_FACTOR_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                weatherFactor: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_PORT_COST_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                portCost: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_IS_IN_SECA_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                isInSeca: action.payload.isInSeca,
                isInSecaOverridden: action.payload.isInSecaOverridden,
                portOperationalRestrictionIds: action.payload.isInSeca
                  ? leg.portOperationalRestrictionIds.add(operationalRestrictionIds.seca)
                  : (() => {
                      const updatedSet = new Set(leg.portOperationalRestrictionIds);
                      updatedSet.delete(operationalRestrictionIds.seca);
                      return updatedSet;
                    })(),
              }
            : leg
        ),
      };
    case VOYAGE_IS_IN_EEA_CHANGED:
      return {
        ...state,
        legs: state.legs.map((leg) =>
          leg.id === action.portId
            ? {
                ...leg,
                isInEea: action.payload,
              }
            : leg
        ),
      };
    case VOYAGE_LOCATION_DELETED:
      return {
        ...state,
        legs: state.legs.filter((leg) => leg.id !== action.portId),
      };
    case VOYAGE_LOCATION_ADDED:
      return {
        ...state,
        legs: [...state.legs, action.payload.leg],
      };
    case VOYAGE_LEGS_LOADED:
      return {
        ...state,
        legs: state.legs.map((leg, index) => {
          const routeAsSequenceOfDistancesThroughOperationalRestrictions =
            action.payload.latestRoutesForLegs.find((item) => item.fromToLocations.id === leg.id)
              ?.routeResult.routeAsSequenceOfDistancesThroughOperationalRestrictions;
          return {
            ...leg,
            inboundRoute: {
              ...leg.inboundRoute,
              variants: leg.inboundRoute.variants.map((variant) => ({
                ...variant,
                routeAsSequenceOfDistancesThroughOperationalRestrictions,
              })),
            },
          };
        }),
      };
    case VOYAGE_ROUTE_VARIANTS_SET:
      /* The route that we got contains information about zones that are sailed through. Here we use that information to set the `portOperationalRestrictionIds` at the entry's locations.
         #ToRemoveIfSwitchingToLocationAPI__Determining_PortOperationalRestrictionIds_From_Route - if we are switching to the approach of getting the `portOperationalRestrictionIds` from a separate query to Location API, then this won't be needed and can be removed. For now, this is not possible as sea/net on production doesn't yet have Prabu's request to [sea/net's `locations/.../GetByAdvancedSearch`](https://net-api.sea.live/locations/index.html#operations-Locations-GetByAdvancedSearch) to return all zones that contain a location (they call these 'parents').
          */

      const changedLegIndex = findIndex(
        state.legs,
        (leg) => leg.id === action.payload.voyageEntryId
      );

      return {
        ...state,
        legs: state.legs.map((voyageEntry, voyageEntryIndex) => {
          const routeSequenceOfDistancesThroughOperationalRestrictionsToUseForPortOperationalRestrictions =
            action.payload.newRouteVariantViewModels
              .map((_) => _.routeAsSequenceOfDistancesThroughOperationalRestrictions)
              .filter(
                (routeAsSequenceOfDistancesThroughOperationalRestrictions) =>
                  !isNil(
                    // We will get undefined for routes that weren't calculated just now but were persisted, because currently the app doesn't persist the `routeAsSequenceOfDistancesThroughOperationalRestrictions`. It only occurs in the routing API results.
                    routeAsSequenceOfDistancesThroughOperationalRestrictions
                  )
              )
              .find((_) => _.length > 0) || [];
          if (voyageEntry.id !== action.payload.voyageEntryId) {
            if (isRouteOutboundFromThisLeg()) {
              const newOperationalRestrictionIds /* First item's operation restriction id's. First one, because the route is an outboud route from this leg. */ =
                routeSequenceOfDistancesThroughOperationalRestrictionsToUseForPortOperationalRestrictions.length >
                0
                  ? routeSequenceOfDistancesThroughOperationalRestrictionsToUseForPortOperationalRestrictions[0]
                      .operationalRestrictionIds
                  : new Set();
              return getUpdatedVoyageEntryWithPortOperationalRestrictionIdsFromRoute(
                voyageEntry,
                newOperationalRestrictionIds
              );
            } else return voyageEntry;

            function isRouteOutboundFromThisLeg() {
              return voyageEntryIndex === changedLegIndex - 1;
            }
          }

          /* Outbound is taken care of in the `voyageEntry.id !== action.payload.voyageEntryId`.
             What's left is `voyageEntry.id === action.payload.voyageEntryId` which contains the destination of the route (in other words, for which this is an inbound route). For this location we need to set the `portOperationalRestrictionIds` from the route as well, especially that it may be the last voyage entry, so there will never be an outbound sailing.*/

          if (
            voyageEntryIndex === 0 &&
            routeSequenceOfDistancesThroughOperationalRestrictionsToUseForPortOperationalRestrictions.length ===
              0
          )
            /* But for the first voyage entry (index == 0), don't allow no-sailing inbound routes to overwrite the `operationalRestrictionIds` because first voyage entry has a valid case of 'no inbound sailing' (for the case of no open location). If we allowed such 'no sailing info' to erase the enties' `operationalRestrictionIds`, it could actually be overwriting correct `operationalRestrictionIds` information determined using the outbound route (it would be just a matter of the order in which the user chooses to fill in the inputs). So, we don't do it and always let this be determined using the 'outbound route' logic above (there will always be an outbound route when there's no open location because otherwise there'd be no sailing at all and no point in using the feature) */
            return voyageEntry;

          const newOperationalRestrictionIds =
            routeSequenceOfDistancesThroughOperationalRestrictionsToUseForPortOperationalRestrictions.length >
            0
              ? routeSequenceOfDistancesThroughOperationalRestrictionsToUseForPortOperationalRestrictions
                  /* Last item's operation restriction id's. Last one, because the route is an inbound route to this leg. */
                  .slice(-1)[0].operationalRestrictionIds
              : new Set();

          return getUpdatedVoyageEntryWithPortOperationalRestrictionIdsFromRoute(
            voyageEntry,
            newOperationalRestrictionIds
          );

          function getUpdatedVoyageEntryWithPortOperationalRestrictionIdsFromRoute(
            originalVoyageEntry,
            newOperationalRestrictionIds
          ) {
            return {
              ...originalVoyageEntry,
              portOperationalRestrictionIds: new Set([
                ...(originalVoyageEntry.portOperationalRestrictionIds || []),
                /**
                 * The applied filter is to preserve the old behavior of calc's hardcoded list of seca ports,
                 * in such case, we only add operational restrictions that are not `seca`, as the `seca`
                 * operational restriction is driven by a hard-coded list [see VOYAGE_SET_PORTS_IN_SECA action].
                 * This is to minimise risk of releasing the 'scrubber sludge ban' functionality.
                 * This can also be removed along with the #ToRemoveIfSwitchingToLocationAPI__Determining_PortOperationalRestrictionIds_From_Route when switching to Location API as a source of port operational restrictions.
                 */
                ...[...newOperationalRestrictionIds].filter(
                  (restrictionId) => restrictionId !== operationalRestrictionIds.seca
                ),
              ]),
            };
          }
        }),
      };
    case VOYAGE_AVOID_SECA_ZONES_CHANGED:
      return {
        ...state,
        avoidSecaZones: action.avoidSecaZones,
      };
    case VOYAGE_TOGGLE_AUTO_INTAKE_CALC:
      return {
        ...state,
        shouldAutoCalculateIntake: !state.shouldAutoCalculateIntake,
      };
    default:
      return state;
  }
};

export function enrichRouteViewModelWithExcludedWaypoints<
  InputRouteViewModelType: RouteWaypointsViewModel,
>({
  routeViewModel,
  waypointsRequestedToExclude,
}: {
  routeViewModel: RouteWaypointsViewModel,
  waypointsRequestedToExclude: Array<IWaypointIdentity>,
}): InputRouteViewModelType {
  return {
    ...routeViewModel,
    waypoints: [
      ...routeViewModel.waypoints,
      /*
       Re-add the waypoints that user requested to avoid. Note though that we don't know which were actually used by the Routing API to change the route - that's because Routing API doesn't tell us which 'waypoints to avoid' were actually used, and this is 'no wonder', because this could actually have multiple possible answers - #RoutingApiDoesntSayWhichWaypointsToAvoidWereActuallyUsed).
       The reason to keep re-adding 'waypoints to avoid' is not to lose this user preference for a waypoint.
       * This could be made simpler if we address the problem #TODORefactorAvoidedWaypoins:
          * using #TODORefactorAvoidedWaypointsOutOfLegs - The user's 'preference to avoid' is kept in the `legs`, and this might be the last leg that the waypoint is stored at. To do that, refactor the storage model: the waypoints that user wants avoided should not be stored in legs, but in a single worksheet member list, to match the UI where it is a single list outside of legs. Storing these per-leg produces surprising behavior when the user moves/edits the legs (making waypoints stay with legs to which they don't apply) and even a loss of input when user deletes such a leg, while another leg's route was actually using that avoidance preference.
          * or #TODORefactorAvoidedWaypointsByAddingHasRequestedState
       When adding the 'waypoints to exclude' we only want to add those actually used in this request (`waypointsRequestedToExclude`), and not ones from all the legs, because they won't always be equivalent - there are ways to get #WaypointsAvoidedInOnlySomeLegs.
      */
      ...waypointsRequestedToExclude.map(createWaypointToAvoidForNewLegRouteFromLegWaypoint),
    ],
  };
}

export function mapRouteResultToRouteViewModel(routeResult) {
  return mapRouteRichInfoToRouteViewModel(mapRouteResultToRouteRichInfo(routeResult));
}

type RouteRichInfo = RouteAllInfo & {
  /** `RouteAllInfo` already contains `waypoints`, but just with `IWaypointIdentity`. We're enriching it here using #IntersectionAlsoIntersectsNestedProperties */
  waypoints: Array<RouteCalcResultWaypoint>,
  routeAsSequenceOfDistancesThroughOperationalRestrictions: IOperationalRestrictionsDistanceSpan[],
};

function mapRouteResultToRouteRichInfo(
  routeResult: RouteCalculationSuccessfulResult
): RouteRichInfo {
  return {
    path: routeResult.path,
    waypoints: routeResult.waypointsOfSignificance.map((waypoint) => ({
      locationId: waypoint.locationId,
      intersections: waypoint.intersections,
    })),
    subtotalsByOperationalRestrictionIds: routeResult.subtotalsByOperationalRestrictionIds,
    routeAsSequenceOfDistancesThroughOperationalRestrictions:
      routeResult.routeAsSequenceOfDistancesThroughOperationalRestrictions,
    routeRetrievedFromRoutingApiOn: routeResult.routeRetrievedFromRoutingApiOn,
    routeRetrievedWithGraphVersion: routeResult.routingApiGraphVersion,
  };
}

function createWaypointToAvoidForNewLegRouteFromLegWaypoint(
  existentLegWaypoint: IWaypointIdentity
): IVoyageLegWaypoint {
  return {
    locationId: existentLegWaypoint.locationId,
    avoid: true,
    /* Don't copy the `unavoidable` flag. This one is only true 'per leg'. Let it change as soon as that leg is removed. */
  };
}

export function getVoyageViewModelFromWorksheetDto({
  voyage,
  cargoes,
  marketSegmentId,
  totalWaitingDays,
}: IWorksheetDto): IVoyageViewModel {
  const newState = {
    draftUnit: voyage.draftUnit,
    avoidSecaZones: voyage.avoidSecaZones || false,
    shouldAutoCalculateIntake: voyage.shouldAutoCalculateIntake || false,
    legs: voyage.legs.map(mapLegDtoToLegViewModel),
    totalWaitingDays: totalWaitingDays,
  };
  if (newState.legs.length === 0)
    // Ensure there is at least one leg. Migration for #ClearingLastVoyageRowInsteadOfAllowingZeroVoyageEntries There is but a handful of worksheets like this.
    newState.legs[0] = createNewEmptyLeg({
      cargoId: singleOrThrow(cargoes).id,
      waypoints: [],
      marketSegmentId: marketSegmentId,
    });

  return newState;
}

function mapLegDtoToLegViewModel(voyageLeg: IVoyageLeg): IVoyageLegViewModel {
  let longitude = null;
  let latitude = null;

  if (isNil(voyageLeg.location.position) === false) {
    longitude = voyageLeg.location.position.longitude;
    latitude = voyageLeg.location.position.latitude;
  }

  return {
    portOperationalRestrictionIds:
      voyageLeg.portOperationalRestrictionIds && new Set(voyageLeg.portOperationalRestrictionIds),
    id: voyageLeg.id,
    type: voyageLeg.voyageLegType,
    locationId: voyageLeg.location.id,
    name: voyageLeg.location.name,
    zone: voyageLeg.location.zone,
    country: voyageLeg.location.country,
    isInSeca: voyageLeg.location.isInSeca,
    isInEea: voyageLeg.location.isInEea,
    isInSecaOverridden: voyageLeg.location.isInSecaOverridden ?? false,
    longitude,
    latitude,
    gear: voyageLeg.gear,
    draft: voyageLeg.draft,
    salinity: voyageLeg.salinity,
    cargoId: voyageLeg.cargoId,
    cargoQuantity: voyageLeg.quantity,
    loadDischargeRate: voyageLeg.loadDischargeRate,
    rateUnit: voyageLeg.loadDischargeRateUnit,
    workingDayType: voyageLeg.workingDayType,
    workingDayMultiplier: voyageLeg.workingDayMultiplier,
    delay: voyageLeg.delay,
    delayUnit: voyageLeg.delayUnit,
    turnAroundTime: voyageLeg.turnAroundTime,
    turnAroundTimeUnit: voyageLeg.turnAroundTimeUnit,
    weatherFactor: voyageLeg.weatherFactor,
    portCost: voyageLeg.portCost,
    loadDischargeConsumptionsOverrideQty: voyageLeg.loadDischargeConsumptionsOverrideQty,
    inboundRoute: {
      variants: voyageLeg.inboundRoute.variants.map((routeVariant) => ({
        fromLocationGeoCoords: routeVariant.fromLocationGeoCoords,
        totalDistance: routeVariant.totalDistance,
        secaDistance: routeVariant.secaDistance,
        subtotalsByOperationalRestrictionIds:
          routeVariant.subtotalsByOperationalRestrictionIds &&
          mapDistancesByOperationalRestrictionsToSubtotalsByOperationalRestrictionIdsViewModel(
            routeVariant.subtotalsByOperationalRestrictionIds
          ),
        waypoints:
          routeVariant.waypoints &&
          routeVariant.waypoints.map((waypoint) => ({
            locationId: waypoint.id,
            unavoidable: waypoint.isUnavoidable,
            avoid: waypoint.isAvoided,
          })),
        path: routeVariant.path && routeVariant.path.map((path) => [path.longitude, path.latitude]),
        routeRetrievedFromRoutingApiOn: routeVariant.routeRetrievedFromRoutingApiOn,
        routeRetrievedWithGraphVersion: routeVariant.routeRetrievedWithGraphVersion,
      })),
    },
  };

  function mapDistancesByOperationalRestrictionsToSubtotalsByOperationalRestrictionIdsViewModel(
    distancesByOperationalRestrictions
  ) {
    const nonRestrictedDistance = getDistanceByVoyageLegByRestrictionIds(
      distancesByOperationalRestrictions
    );
    const secaOnlyDistance = getDistanceByVoyageLegByRestrictionIds(
      distancesByOperationalRestrictions,
      operationalRestrictionIds.seca
    );
    const sludgeDischargeBanDistance = getDistanceByVoyageLegByRestrictionIds(
      distancesByOperationalRestrictions,
      operationalRestrictionIds.sludgeDischargeBan
    );

    const secaAndSludgeDischargeBanDistance = getDistanceByVoyageLegByRestrictionIds(
      distancesByOperationalRestrictions,
      operationalRestrictionIds.sludgeDischargeBan,
      operationalRestrictionIds.seca
    );

    return [
      {
        operationalRestrictionIds: new Set(),
        userEditedDistance:
          nonRestrictedDistance != null ? nonRestrictedDistance.userEditedDistance : 0,
        routingApiDistance:
          nonRestrictedDistance != null ? nonRestrictedDistance.routingApiDistance : 0,
      },
      {
        operationalRestrictionIds: new Set([operationalRestrictionIds.seca]),
        userEditedDistance: secaOnlyDistance != null ? secaOnlyDistance.userEditedDistance : 0,
        routingApiDistance: secaOnlyDistance != null ? secaOnlyDistance.routingApiDistance : 0,
      },
      {
        operationalRestrictionIds: new Set([operationalRestrictionIds.sludgeDischargeBan]),
        userEditedDistance:
          sludgeDischargeBanDistance != null ? sludgeDischargeBanDistance.userEditedDistance : 0,
        routingApiDistance:
          sludgeDischargeBanDistance != null ? sludgeDischargeBanDistance.routingApiDistance : 0,
      },
      {
        operationalRestrictionIds: new Set([
          operationalRestrictionIds.sludgeDischargeBan,
          operationalRestrictionIds.seca,
        ]),
        userEditedDistance:
          secaAndSludgeDischargeBanDistance != null
            ? secaAndSludgeDischargeBanDistance.userEditedDistance
            : 0,
        routingApiDistance:
          secaAndSludgeDischargeBanDistance != null
            ? secaAndSludgeDischargeBanDistance.routingApiDistance
            : 0,
      },
    ];

    function getDistanceByVoyageLegByRestrictionIds(
      distancesByOperationalRestrictionIds,
      ...restrictionIds
    ) {
      return (
        distancesByOperationalRestrictionIds &&
        distancesByOperationalRestrictionIds.find(
          (subtotal) =>
            restrictionIds.length === Array.from(subtotal.operationalRestrictionIds).length &&
            restrictionIds.every((id) =>
              Array.from(subtotal.operationalRestrictionIds).includes(id)
            )
        )
      );
    }
  }
}

export function createNewEmptyLeg({
  cargoId,
  waypointsToAvoidLocationIds,
  marketSegmentId,
  speedAndConsumption,
}) {
  let voyageLegBuilder = new VoyageLegBuilder()
    .cargoId(cargoId)
    .waypointsToAvoidLocationIds(waypointsToAvoidLocationIds)
    .weatherFactor(marketSegmentId);

  if (speedAndConsumption.isTankerIndexVessel) {
    voyageLegBuilder = voyageLegBuilder.withPortConsumption(speedAndConsumption);
  }

  if (marketSegmentId === marketSegmentIds.wetCargo) {
    voyageLegBuilder.rateUnit(METRIC_TONNES_PER_HOUR.key);
    voyageLegBuilder.gear(LOADING.key);
  }

  return voyageLegBuilder.build();
}

function setConsumptionOverrideQty(state: any, action: any) {
  const newState = { ...state };

  newState.legs.forEach((leg) => {
    if (leg.type === LOAD.key) {
      leg.loadDischargeConsumptionsOverrideQty = action.isTankerIndexVessel
        ? action.speedAndCons[SandCType.LOADING.id].consumption
        : undefined;
    }

    if (leg.type === DISCHARGE.key) {
      leg.loadDischargeConsumptionsOverrideQty = action.isTankerIndexVessel
        ? action.speedAndCons[SandCType.DISCHARGING.id].consumption
        : undefined;
    }
  });

  return newState;
}

function resetConsumptionOverrideQty(state: any) {
  const newState = { ...state };

  newState.legs.forEach((leg) => {
    leg.loadDischargeConsumptionsOverrideQty = undefined;
  });

  return newState;
}

function updateConsumptionOverrideQty(state: any, action: any) {
  const newState = { ...state };
  const index = newState.legs.findIndex((leg) => leg.id === action.legId);
  newState.legs[index].loadDischargeConsumptionsOverrideQty =
    action.loadDischargeConsumptionsOverrideQty;
  return newState;
}
