import React from 'react';
import classNames from 'classnames';
import { ReactComponent as ErrorOutlineSvg } from './error_outline.svg';
import { ReactComponent as OpenPopupSvg } from './open_popup.svg';
import { ReactComponent as SortByAlphaSvg } from './sort-by-alpha.svg';
import { ReactComponent as SortByDateSvg } from './sort-by-date.svg';
import { ReactComponent as SortCustomSvg } from './sort-custom.svg';

export const iconEnum = {
  AddCircle: 'add_circle_outline',
  RemoveCircle: 'remove_circle_outline',
  AddCircleOutline: 'icon--add-circle-outline',
  ArrowDropDown: 'icon--arrow-drop-down',
  ArrowDropRight: 'icon--arrow-drop-right',
  ArrowDropLeft: 'icon--arrow-drop-left',
  ArrowForward: 'icon--arrow-forward',
  ArrowBack: 'icon--arrow-back',
  Ascending: 'icon--ascending',
  Cancel: 'icon--cancel',
  ChevronUp: 'icon--chevron-up',
  ChevronDown: 'icon--chevron-down',
  Close: 'icon--close',
  ContentCopy: 'icon--content-copy',
  ContentExport: 'icon--export',
  Delete: 'icon--delete',
  Descending: 'icon--descending',
  Discharge: 'icon--discharge',
  Filter: 'icon--filter',
  FileDownload: 'icon--file-download',
  Flag: 'icon--flag',
  FuelStop: 'icon--fuel-stop',
  InNegotiation: 'icon--in-negotiation',
  InfoOutline: 'icon--info-outline',
  LeftChevron: 'icon--chevron-left',
  Lightbulb: 'icon--lightbulb-outline',
  Load: 'icon--load',
  ModeEdit: 'icon--mode-edit',
  MoreVert: 'icon--more-vert',
  Refresh: 'icon--autorenew',
  Retry: 'icon--refresh',
  RightChevron: 'icon--chevron-right',
  Search: 'icon--search',
  Ship: 'icon--ship',
  Tick: 'icon--tick',
  Warning: 'icon--warning',
  Workbook: 'icon--workbook',
  Calculator: 'icon--calculator',
  Save: 'icon--save',
  TimeUpdate: 'icon--time-update',
  Sort: 'icon--sort',
  NoSort: 'icon--no-sort',
  Route: 'icon--distance-calculator',
};

export const iconSvgEnum = {
  ErrorOutline: ErrorOutlineSvg,
  OpenPopUp: OpenPopupSvg,
  CustomSort: SortCustomSvg,
  SortByAlpha: SortByAlphaSvg,
  SortByDate: SortByDateSvg,
};

const BaseIcon = ({ icon, className, ...rest }) => {
  let IconComponent = null;
  let classes = classNames('has-icon', icon, className);

  if (iconEnum[icon]) {
    classes = classNames(classes, iconEnum[icon]);
  } else if (iconSvgEnum[icon]) {
    IconComponent = iconSvgEnum[icon];
  }

  return (
    <>
      {IconComponent ? (
        <div className={classes} {...rest}>
          <IconComponent />
        </div>
      ) : (
        <div role="img" className={classes} {...rest} />
      )}
      {rest && rest.remainingnotifications && (
        <span className="notice-box__type-icon-indicator">{rest.remainingnotifications}</span>
      )}
    </>
  );
};

// Actions
export const CancelIcon = (props) => <BaseIcon icon={iconEnum.Cancel} {...props} />;
export const CloseIcon = (props) => <BaseIcon icon={iconEnum.Close} {...props} />;
export const ContentCopyIcon = (props) => <BaseIcon icon={iconEnum.ContentCopy} {...props} />;
export const ModeEditIcon = (props) => <BaseIcon icon={iconEnum.ModeEdit} {...props} />;

export const FileDownloadIcon = (props) => <BaseIcon icon={iconEnum.FileDownload} {...props} />;

// Alerts
export const TickIcon = (props) => <BaseIcon icon={iconEnum.Tick} {...props} />;
export const InfoOutlineIcon = (props) => <BaseIcon icon={iconEnum.InfoOutline} {...props} />;
export const LightbulbIcon = (props) => <BaseIcon icon={iconEnum.Lightbulb} {...props} />;
export const FlagIcon = (props) => <BaseIcon icon={iconEnum.Flag} {...props} />;
export const WarningIcon = (props) => <BaseIcon icon={iconEnum.Warning} {...props} />;

// Arrows
export const ArrowDropDownIcon = (props) => <BaseIcon icon={iconEnum.ArrowDropDown} {...props} />;
export const ArrowDropRightIcon = (props) => <BaseIcon icon={iconEnum.ArrowDropRight} {...props} />;
export const ArrowDropLeftIcon = (props) => <BaseIcon icon={iconEnum.ArrowDropLeft} {...props} />;
export const ArrowForwardIcon = (props) => <BaseIcon icon={iconEnum.ArrowForward} {...props} />;
export const ArrowBackIcon = (props) => <BaseIcon icon={iconEnum.ArrowBack} {...props} />;
export const AscendingIcon = (props) => <BaseIcon icon={iconEnum.Ascending} {...props} />;
export const DescendingIcon = (props) => <BaseIcon icon={iconEnum.Descending} {...props} />;
export const ChevronUpIcon = (props) => <BaseIcon icon={iconEnum.ChevronUp} {...props} />;
export const ChevronDownIcon = (props) => <BaseIcon icon={iconEnum.ChevronDown} {...props} />;
export const LeftChevronIcon = (props) => <BaseIcon icon={iconEnum.LeftChevron} {...props} />;
export const RightChevronIcon = (props) => <BaseIcon icon={iconEnum.RightChevron} {...props} />;
export const DischargeIcon = (props) => <BaseIcon icon={iconEnum.Discharge} {...props} />;
export const LoadIcon = (props) => <BaseIcon icon={iconEnum.Load} {...props} />;
export const ContentExportIcon = (props) => <BaseIcon icon={iconEnum.ContentExport} {...props} />;

// Marine
export const FuelStopIcon = (props) => <BaseIcon icon={iconEnum.FuelStop} {...props} />;
export const ShipIcon = (props) => <BaseIcon icon={iconEnum.Ship} {...props} />;

// Menus
export const MoreVertIcon = (props) => <BaseIcon icon={iconEnum.MoreVert} {...props} />;

// Miscellaneous
export const WorkbookIcon = (props) => <BaseIcon icon={iconEnum.Workbook} {...props} />;

// Search
export const FilterIcon = (props) => <BaseIcon icon={iconEnum.Filter} {...props} />;
export const SearchIcon = (props) => <BaseIcon icon={iconEnum.Search} {...props} />;

// Status
export const RefreshIcon = (props) => <BaseIcon icon={iconEnum.Refresh} {...props} />;
export const RetryIcon = (props) => <BaseIcon icon={iconEnum.Retry} {...props} />;
export const InNegotiationIcon = (props) => <BaseIcon icon={iconEnum.InNegotiation} {...props} />;

// Toggles
export const AddCircleOutlineIcon = (props) => <BaseIcon icon={iconEnum.AddCircle} {...props} />;
export const DeleteIcon = (props) => <BaseIcon icon={iconEnum.RemoveCircle} {...props} />;
export const RouteIcon = (props) => <BaseIcon icon={iconEnum.Route} {...props} />;
export const ErrorOutlineIcon = (props) => <BaseIcon icon="ErrorOutline" {...props}></BaseIcon>;
export const OpenPopupIcon = (props) => <BaseIcon icon="OpenPopUp" {...props}></BaseIcon>;
export const CustomSortIcon = (props) => <BaseIcon icon="CustomSort" {...props}></BaseIcon>;
export const SortByAlphaIcon = (props) => <BaseIcon icon="SortByAlpha" {...props}></BaseIcon>;
export const SortByDateIcon = (props) => <BaseIcon icon="SortByDate" {...props}></BaseIcon>;
