import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

interface DragAndDropItemProps {
  children: React.ReactNode;
  draggableId: string;
  index: number;
  className?: string;
  isDragDisabled?: boolean;
  onMouseDown?: () => void;
}

const DragAndDropItem = ({
  children,
  draggableId,
  index,
  className,
  isDragDisabled,
  onMouseDown,
}: DragAndDropItemProps) => {
  return (
    <Draggable
      key={draggableId}
      draggableId={draggableId}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={className}
          onMouseDown={onMouseDown}
          style={{
            ...provided.draggableProps.style,
            cursor: snapshot.isDragging ? 'not-allowed' : 'pointer',
          }}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default DragAndDropItem;
