import React, { useState, useEffect, FC } from 'react';
import classNames from 'classnames';
import './styles.scss';
import WorkbookAutocompleteContainer from 'components/workbook-autocomplete';
import { IconButton, PrimaryButton, SecondaryButton } from 'components/button';
import { iconEnum } from 'components/icons';
import { Modal } from 'components/modal';
import { WorksheetModalInput } from 'components/worksheet-modal-input';
import { setWorksheetApiVersion } from 'utilities/functions/set-worksheet-api-version';
import { getHostNameFromUrlString } from 'utilities/url';
import Async from 'react-async';
import EmitNotice from 'components/notices-emitter/emit-notice';
import {
  COPY_WORKSHEET_SUCCESS_NOTICE,
  COPY_WORKSHEET_FAILURE_NOTICE,
  MOVE_WORKSHEET_SUCCESS_NOTICE,
  MOVE_WORKSHEET_FAILURE_NOTICE,
} from 'constants/enums/emit-notices';
import { appRootNoticesEmitter } from '../app-root';
import ErrorBox from 'components/notice-box/error-box';
import SuccessBox from 'components/notice-box/success-box';
import storePromiseOnCall from 'utilities/functions/store-promise-on-call';
import { connect } from 'react-redux';
import { selector } from './selector';
import { bindActionCreators } from 'redux';
import * as worksheetClient from 'api/clients/worksheet';
import { loadMyWorkbooks } from 'actions/workbook';
import { isEmptyGuid } from 'utilities/guid';
import { useNavigate } from 'react-router-dom';
import { Overlay } from '../overlay';

type ICopyMoveWorksheetModal = {
  handleClose: () => void,
  isVisible: boolean,
  modalType: string,
  setWorksheetControlsEnabled: () => void,
  activeWorksheet?: {},
  nextWorksheetId: string,
  loadMyWorkbooks: () => void,
};
export const CopyMoveWorksheetModal: FC<ICopyMoveWorksheetModal> = ({
  handleClose,
  isVisible,
  modalType,
  setWorksheetControlsEnabled,
  activeWorksheet,
  nextWorksheetId,
  loadMyWorkbooks,
}) => {
  const [selectedWorkbookId, setSelectedWorkbookId] = useState('');
  const [selectedWorkbookName, setSelectedWorkbookName] = useState('');
  const [copyMoveWorksheetPromise, setCopyMoveWorksheetPromise] = useState(null);
  const [newWorkbookName, setNewWorkbookName] = useState('');
  const [moveFailed, setMoveFailed] = useState(false);
  const [copyFailed, setCopyFailed] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isMoved, setIsMoved] = useState(false);
  const navigate = useNavigate();
  const actionButtonClasses = classNames('modal-button', {
    'success-button': isCopied || isMoved,
    'warning-button': copyFailed || moveFailed,
  });
  const extractWorkbookId = (id, name) => {
    setSelectedWorkbookId(id);
    setSelectedWorkbookName(name);
  };
  const extractName = (name) => {
    setNewWorkbookName(name);
  };

  useEffect(() => {
    if (isVisible) {
      setMoveFailed(false);
      setCopyFailed(false);
    }
    if (!isVisible) {
      setSelectedWorkbookId('');
      setSelectedWorkbookName('');
      setNewWorkbookName('');
    }
  }, [isVisible]);
  useEffect(() => {
    if (selectedWorkbookId !== '') {
      document.getElementById('copyWorksheetModalButton').focus();
    }
  }, [selectedWorkbookId]);

  const copyToExisting = storePromiseOnCall(
    async () => {
      setWorksheetControlsEnabled(false);
      setWorksheetApiVersion(activeWorksheet);
      try {
        const result = await worksheetClient.copyWorksheetToExistingWorkbook({
          worksheetId: activeWorksheet.id,
          selectedWorkbookId: selectedWorkbookId,
          workbookName: selectedWorkbookName,
          worksheetName: `${activeWorksheet.name} - Copy`,
          createdFromProgramId: 'SeaCalcUI',
          createdFromOriginId: getHostNameFromUrlString(document.location.origin),
        });

        setIsCopied(true);
        setTimeout(() => {
          handleClose();
          setIsCopied(false);
        }, 2150);

        setWorksheetControlsEnabled(true);

        return result;
      } catch (err) {
        setCopyFailed(true);
        setWorksheetControlsEnabled(true);
        throw err;
      }
    },
    (copyMoveWorksheetPromise) => {
      setCopyMoveWorksheetPromise({ copyMoveWorksheetPromise });
    }
  );

  const copyToNew = storePromiseOnCall(
    async () => {
      setWorksheetControlsEnabled(false);
      setWorksheetApiVersion(activeWorksheet);
      try {
        const result = await worksheetClient.copyWorksheetToNewWorkbook({
          worksheetId: activeWorksheet.id,
          workbookName: newWorkbookName,
          worksheetName: `${activeWorksheet.name} - Copy`,
          createdFromProgramId: 'SeaCalcUI',
          createdFromOriginId: getHostNameFromUrlString(document.location.origin),
        });

        setIsCopied(true);
        setTimeout(() => {
          handleClose();
          setIsCopied(false);
        }, 2150);

        loadMyWorkbooks();
        setWorksheetControlsEnabled(true);

        return result;
      } catch (err) {
        setCopyFailed(true);
        setWorksheetControlsEnabled(true);
        throw err;
      }
    },
    (copyMoveWorksheetPromise) => {
      setCopyMoveWorksheetPromise({ copyMoveWorksheetPromise });
    }
  );

  const moveToExisting = storePromiseOnCall(
    async () => {
      setWorksheetControlsEnabled(false);
      setWorksheetApiVersion(activeWorksheet);
      try {
        const result = await worksheetClient.moveWorksheetToExistingWorkbook({
          worksheetId: activeWorksheet.id,
          selectedWorkbookId: selectedWorkbookId,
          workbookName: selectedWorkbookName,
          createdFromProgramId: 'SeaCalcUI',
          createdFromOriginId: getHostNameFromUrlString(document.location.origin),
        });

        setIsMoved(true);
        setTimeout(() => {
          handleClose();
          setIsMoved(false);
        }, 2150);

        loadMyWorkbooks();
        setWorksheetControlsEnabled(true);

        return result;
      } catch (err) {
        setMoveFailed(true);
        setWorksheetControlsEnabled(true);
        throw err;
      }
    },
    (copyMoveWorksheetPromise) => {
      setCopyMoveWorksheetPromise({ copyMoveWorksheetPromise });
    }
  );

  const moveToNew = storePromiseOnCall(
    async () => {
      setWorksheetControlsEnabled(false);
      setWorksheetApiVersion(activeWorksheet);
      try {
        const result = await worksheetClient.moveWorksheetToNewWorkbook({
          worksheetId: activeWorksheet.id,
          workbookName: newWorkbookName,
          createdFromProgramId: 'SeaCalcUI',
          createdFromOriginId: getHostNameFromUrlString(document.location.origin),
        });

        setIsMoved(true);
        setTimeout(() => {
          handleClose();
          setIsMoved(false);
        }, 2150);

        loadMyWorkbooks();
        setWorksheetControlsEnabled(true);

        return result;
      } catch (err) {
        setMoveFailed(true);
        setWorksheetControlsEnabled(true);
        throw err;
      }
    },
    (copyMoveWorksheetPromise) => {
      setCopyMoveWorksheetPromise({ copyMoveWorksheetPromise });
    }
  );

  const COPY_TO_EXISTING = {
    header: 'Copy to an existing workbook',
    input: 'auto-complete',
    disabled: !selectedWorkbookId,
    onClick: copyToExisting,
    icon: isCopied ? iconEnum.Tick : iconEnum.ContentCopy,
    buttonLabel: isCopied ? 'Copied' : copyFailed ? 'Try again' : 'Copy',
  };

  const COPY_TO_NEW = {
    header: 'Copy to a new workbook',
    input: 'regular-input',
    disabled: !newWorkbookName?.trim(),
    onClick: copyToNew,
    icon: isCopied ? iconEnum.Tick : iconEnum.ContentCopy,
    buttonLabel: isCopied ? 'Copied' : copyFailed ? 'Try again' : 'Copy',
  };

  const MOVE_TO_EXISTING = {
    header: 'Move to an existing workbook',
    input: 'auto-complete',
    disabled: !selectedWorkbookId,
    onClick: moveToExisting,
    icon: isMoved ? iconEnum.Tick : iconEnum.ContentCopy,
    buttonLabel: isMoved ? 'Moved' : moveFailed ? 'Try again' : 'Move',
  };

  const MOVE_TO_NEW = {
    header: 'Move to a new workbook',
    input: 'regular-input',
    disabled: !newWorkbookName?.trim(),
    onClick: moveToNew,
    icon: isMoved ? iconEnum.Tick : iconEnum.ContentCopy,
    buttonLabel: isMoved ? 'Moved' : moveFailed ? 'Try again' : 'Move',
  };

  const getModalConfig = (type) => {
    switch (type) {
      case 'COPY_TO_EXISTING':
        return COPY_TO_EXISTING;
      case 'COPY_TO_NEW':
        return COPY_TO_NEW;
      case 'MOVE_TO_EXISTING':
        return MOVE_TO_EXISTING;
      case 'MOVE_TO_NEW':
        return MOVE_TO_NEW;
      default:
        return;
    }
  };
  const modalConfig = getModalConfig(modalType);

  let keysPressed = {};

  const handleKeyPress = () => {
    const keyEvent = (event) => {
      keysPressed[event.key] = true;
      if (keysPressed['Control'] && event.key === 'Enter') {
        document.getElementById('copyWorksheetModalButton').click();
      }
    };

    document.querySelector('.copy-move-worksheet').addEventListener('keydown', keyEvent);
    document.addEventListener('keyup', (event) => {
      delete keysPressed[event.key];
    });
    return () => {
      document.removeEventListener('keydown', keyEvent);
    };
  };
  const useMountEffect = (fn) => useEffect(fn, []);
  useMountEffect(handleKeyPress);

  const onWillDismiss = () => {
    if (isMoved) {
      const redirectPath = isEmptyGuid(nextWorksheetId)
          ? `/workbook/${activeWorksheet.workbookId}/`
          : `/workbook/${activeWorksheet.workbookId}/worksheet/${nextWorksheetId}`;
      navigate(redirectPath);
    }
  };
  return (
    <>
      <Modal isVisible={isVisible} className="copy-move-worksheet">
        <span className="modal-header">{modalConfig?.header}</span>
        {modalConfig?.input === 'auto-complete' && (
          <WorkbookAutocompleteContainer pickSelectedWorkbook={extractWorkbookId} />
        )}
        {modalConfig?.input === 'regular-input' && (
          <WorksheetModalInput pickProvidedName={extractName} />
        )}
        <div className="modal-buttons">
          <SecondaryButton onClick={handleClose} className="modal-button">
            Cancel
          </SecondaryButton>
          <PrimaryButton
            id="copyWorksheetModalButton"
            disabled={modalConfig?.disabled && !isCopied && !copyFailed}
            onClick={modalConfig?.onClick}
            className={actionButtonClasses}
            icon={modalConfig?.icon}
          >
            {modalConfig?.buttonLabel}
          </PrimaryButton>
        </div>
      </Modal>
      <Async promise={copyMoveWorksheetPromise?.copyMoveWorksheetPromise}>
        <Async.Rejected>
          {(error) => {
            return (
              <EmitNotice
                type={moveFailed ? MOVE_WORKSHEET_FAILURE_NOTICE : COPY_WORKSHEET_FAILURE_NOTICE}
                emitter={appRootNoticesEmitter}
                worksheetId=""
              >
                {({ dismiss, additionalProps }) => {
                  return (
                    <ErrorBox additionalprops={additionalProps}>
                      <h1>Unsuccessful</h1>
                      {`Failed to ${moveFailed ? 'move' : 'copy'} worksheet. Please try again`}
                      <IconButton
                        aria-label="Dismiss"
                        icon={iconEnum.Close}
                        className="notice-box__standard-dismiss-button"
                        onClick={dismiss}
                        diagnosticId="CopyWorksheetButton/DismissError"
                      />
                    </ErrorBox>
                  );
                }}
              </EmitNotice>
            );
          }}
        </Async.Rejected>
        <Async.Fulfilled>
          {(copyMoveWorksheetResult) => {
            return (
              <>
                <EmitNotice
                  type={isMoved ? MOVE_WORKSHEET_SUCCESS_NOTICE : COPY_WORKSHEET_SUCCESS_NOTICE}
                  emitter={appRootNoticesEmitter}
                  worksheetId={copyMoveWorksheetResult.worksheetId}
                  onWillDismiss={onWillDismiss}
                >
                  {({ dismiss, additionalProps, dismissNonActiveWorksheetMessages }) => (
                    <SuccessBox additionalprops={additionalProps}>
                      <h1>Success</h1>
                      {`Worksheet: ${activeWorksheet?.name} ${
                        isMoved ? 'moved' : 'copied'
                      } to Workbook: ${modalConfig?.input === 'regular-input' ? newWorkbookName : selectedWorkbookName}`}
                      <a
                        className="notice-box__button"
                        href={`#/workbook/${copyMoveWorksheetResult.workbookId}/worksheet/${copyMoveWorksheetResult.worksheetId}`}
                        onClick={dismissNonActiveWorksheetMessages}
                      >
                        OPEN
                      </a>
                      <IconButton
                        aria-label="Dismiss"
                        icon={iconEnum.Close}
                        className="notice-box__standard-dismiss-button"
                        onClick={dismiss}
                        diagnosticId="CopyWorksheetButton/DismissSuccessfulCopy"
                      />
                    </SuccessBox>
                  )}
                </EmitNotice>
                <Overlay
                  hidden={
                    copyMoveWorksheetResult.actionName === 'CopyToExistingWorkbook' ||
                    copyMoveWorksheetResult.actionName === 'CopyToNewWorkbook'
                  }
                  data-testid="data-testid__overlay"
                />
              </>
            );
          }}
        </Async.Fulfilled>
      </Async>
    </>
  );
};
const mapStateToProps = selector;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadMyWorkbooks,
    },
    dispatch
  );
}
export const CopyMoveWorksheetModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyMoveWorksheetModal);
