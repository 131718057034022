import React, { FC, useState, useRef, useEffect } from 'react';
import Downshift from 'downshift';
import { emptyGuid, isEmptyGuid } from 'utilities/guid';
import { cdnUrl } from 'config';
import classnames from 'classnames';
import userContext from 'user-context';
import { isDry } from 'constants/market-segments';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { debounceDefaults } from 'constants/defaults/debounce';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import { PortMenuItem, searchPorts } from 'api/clients/location';
import isNil from 'lodash/isNil';
import './styles.scss';
import ProgressBar from 'components/progress-bar';
import Size from 'components/progress-bar/size';
import ValidationError from '../validation/ui/validation-error';
import { CloseIcon, ModeEditIcon } from '../icons';

interface IPortAutocomplete {
  port: Port;
  onChange: Function;
  isMandatory?: boolean;
  isReadonly?: boolean;
  marketSegmentId?: string;
  className?: string | null;
  autoPopulatedField?: boolean;
  initialOpenPosition?: Record<string, any>;
  activeVesselName?: string;
}
const PortAutocomplete: FC<IPortAutocomplete> = ({
  port,
  isMandatory,
  isReadonly,
  onChange,
  marketSegmentId,
  className,
  autoPopulatedField,
  initialOpenPosition,
  activeVesselName,
}) => {
  const [ports, setPorts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [currentPort, setCurrentPort] = useState(port);
  const emptyLocation = {
    locationId: emptyGuid,
    locationName: '',
  };
  const initialLocation = {
    locationId: initialOpenPosition?.id,
    locationName: initialOpenPosition?.name,
    country: initialOpenPosition?.country,
    latitude: initialOpenPosition?.latitude,
    longitude: initialOpenPosition?.longitude,
    zone: initialOpenPosition?.zone,
  };

  const [isEdited, setIsEdited] = useState(false);
  const inputRef = useRef();
  const cache = useRef({});
  const cleanupTasks: Array<() => void> = [];
  const flagUrl: string = `${cdnUrl}/Data/PublicArtificats/flags/ALPHA2/16/`;
  const itemToString = (item: Port | null) => {
    if (isNil(item)) {
      return '';
    }
    return `${item.locationName || ''}`;
  };
  const isEmptyValue = () => !port.locationName;

  const fetchAndPopulateOptions = debounce(
    async (searchTerm: string, cancelToken): void => {
      try {
        const foundOptions =
          cache.current[searchTerm] ||
          (cache.current[searchTerm] = await searchPorts(searchTerm, cancelToken));

        cancelToken.throwIfRequested();
        setPorts(foundOptions);
      } catch (err) {
        if (axios.isCancel(err)) {
          trackEvent(
            'PortAutocompte/searchPorts',
            'Worksheet Port Search or Autocomplete Cancelled',
            {
              searchTerm,
            },
            {},
            eventDestination.ANALYSIS
          );
          return;
        }
        setIsError(true);
        throw err;
      } finally {
        if (!cancelToken.reason) setIsLoading(false);
      }
    },
    debounceDefaults.wait,
    {
      leading: debounceDefaults.leading,
      maxWait: debounceDefaults.maxWait,
    }
  );
  const onInputChange = (searchTerm: string, { closeMenu }): void => {
    if (cancelTokenSource !== null) {
      cancelTokenSource.cancel('Cancelled!');
    }
    setIsError(false);
    if (!isSearchTermValid(searchTerm)) {
      return;
    }
    if (isEmpty(searchTerm)) {
      const timeoutHandle = setTimeout(closeMenu, 0);
      cleanupTasks.push(() => clearTimeout(timeoutHandle));
      return;
    }

    const currentCancelTokenSource = axios.CancelToken.source();

    setCancelTokenSource(currentCancelTokenSource);
    cleanupTasks.push(() => currentCancelTokenSource.cancel());
    setIsLoading(true);

    fetchAndPopulateOptions(searchTerm, currentCancelTokenSource.token);
  };

  const onChangeHandler = (item: PortMenuItem) => {
    onChange(item);
  };

  const isSearchTermValid = (searchTerm: string): boolean => {
    return !isNil(searchTerm) && !isEmpty(searchTerm) && searchTerm !== port.locationName;
  };

  const onBlurHandler = (event: SyntheticFocusEvent<HTMLInputElement>, closeMenu, reset): void => {
    const val = event && event.target && event.target.value;

    if (isEmpty(val)) {
      event.nativeEvent.preventDownshiftDefault = true;
      onChangeHandler(emptyLocation);
    }
    if (autoPopulatedField) {
      setIsEdited(
        initialLocation?.locationName !== port.locationName &&
          activeVesselName !== '' &&
          !isEmptyGuid(initialLocation?.locationId)
          ? true
          : false
      );
      closeMenu();
      reset();
    }
  };

  const onFocusHandler = (event: SyntheticFocusEvent<HTMLInputElement>): void => {
    event.target.select();
    if (autoPopulatedField) {
      setIsEdited(true);
    }
  };

  const onKeyDownHandler = (
    event: SyntheticKeyboardEvent<HTMLInputElement>,
    selectHighlightedItem: Function,
    isOpen: boolean
  ): void => {
    const keyCode = event?.key;

    if (isNil(keyCode) === false && keyCode === 'Tab') {
      selectHighlightedItem();
    }
    if (isNil(keyCode) === false && keyCode === 'Escape' && !isOpen) {
      event.nativeEvent.preventDownshiftDefault = true;
    }
  };
  const getClassNameIfHighlighted = (highlightedIndex: number, index: number): string => {
    return highlightedIndex === index ? 'port-autocomplete__menu-item--highlighted' : '';
  };

  const handleEditClick = () => {
    inputRef.current.select();
    setIsEdited(true);
  };

  const handleClearClick = () => {
    setCurrentPort(!activeVesselName ? emptyLocation : initialLocation);
    setIsEdited(false);
    onChange(!activeVesselName ? emptyLocation : initialLocation);
  };

  useEffect(() => {
    setCurrentPort(port);

    const hasLocationChanged = initialLocation?.locationName !== port?.locationName;
    const hasActiveVessel = activeVesselName !== '';
    const isValidInitialLocation = !isEmptyGuid(initialLocation?.locationId);
    const isLocationNameEmpty = port?.locationName !== '';
    const shouldEdit =
      hasLocationChanged && hasActiveVessel && isValidInitialLocation && isLocationNameEmpty;

    setIsEdited(shouldEdit);
  }, [port.locationName, activeVesselName]);
  const renderFoundOptions = (getItemProps, highlightedIndex, inputValue) =>
    ports.length === 0 && inputValue !== '' ? (
      <div
        className="port-autocomplete__menu-item--nothing-found-notice"
        data-testid="nothing-found-notice"
      >
        There are no locations that match your search criteria. Please check and try again.
      </div>
    ) : (
      ports.map((option, index) => renderOption(option, index, highlightedIndex, getItemProps))
    );
  const renderOption = (port, index, highlightedIndex, getItemProps) => (
    <div
      {...getItemProps({ item: port })}
      key={port.locationId}
      className={`port-autocomplete__menu-item ${getClassNameIfHighlighted(
        highlightedIndex,
        index
      )}`}
      data-testid="port-item"
    >
      <div className="port-autocomplete__menu-item--part port-autocomplete__menu-item--flag">
        {port.countryCode && (
          <img
            alt={port.countryCode}
            src={`${encodeURI(flagUrl)}${encodeURIComponent(port.countryCode)}.png`}
          />
        )}
      </div>
      <div className="port-autocomplete__menu-item--part port-autocomplete__menu-item--name">
        <div className="port-autocomplete__menu-item--name-inner">{port.locationName}</div>
      </div>
      <div className="port-autocomplete__menu-item--part port-autocomplete__menu-item--country">
        <div className="port-autocomplete__menu-item--country-inner">{port.country}</div>
      </div>
      {
        /* #PreventUndesiredNoDivisionFallbackOnZoneCodes - the data source service doesn't allow to specify division (always takes it from user), and falls back to one that is undesired for external client companies. We rather chose to not show it then. */
        userContext.userInfo.isInHouseCompany && isDry(marketSegmentId) && (
          <div className="port-autocomplete__menu-item--part port-autocomplete__menu-item--zone">
            {port.zone}
          </div>
        )
      }
      <div className="port-autocomplete__menu-item--part port-autocomplete__menu-item--aliases">
        <div className="port-autocomplete__menu-item--aliases-inner">{port.locationAliases}</div>
      </div>
    </div>
  );

  return (
    <Downshift
      onChange={onChangeHandler}
      onInputValueChange={onInputChange}
      initialSelectedItem={port}
      selectedItem={currentPort}
      itemToString={itemToString}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        highlightedIndex,
        selectHighlightedItem,
        closeMenu,
        inputValue,
        reset,
      }) => (
        <div className="port-autocomplete">
          <input
            {...getInputProps({
              placeholder: 'Enter port',
              onBlur: (event) => onBlurHandler(event, closeMenu, reset),
              onFocus: (event) => onFocusHandler(event),
              onKeyDown: (event) => onKeyDownHandler(event, selectHighlightedItem, isOpen),
            })}
            maxLength={160}
            className={classnames('port-autocomplete__input', {
              'port-autocomplete__input-isMandatory': isMandatory && isEmptyValue(),
              'port-autocomplete__input--error': isError,
              'port-autocomplete__input--tankerField-editable': autoPopulatedField,
              'is-edited-mode': isEdited,
              'port-autocomplete__input-isReadonly': isReadonly,
            })}
            ref={inputRef}
            disabled={isReadonly}
          />
          {autoPopulatedField && (
            <button
              className="port-autocomplete__input-edit-button"
              type="button"
              onClick={isEdited ? handleClearClick : handleEditClick}
              tabIndex={-1}
            >
              {isEdited ? (
                <CloseIcon className="port-autocomplete__input-edit-button--icon" />
              ) : (
                <ModeEditIcon className="port-autocomplete__input-edit-button--icon" />
              )}
            </button>
          )}
          {isOpen && (
            <div
              className={classnames('port-autocomplete__menu', {
                'port-autocomplete__menu-error': isError,
              })}
              data-testid="menu"
              role="listbox"
            >
              {isLoading ? (
                <div className="port-autocomplete__menu-item">
                  <ProgressBar size={Size.MEDIUM} />
                </div>
              ) : isError ? (
                <div data-testid="error-notice">
                  <ValidationError
                    validationLevel="error"
                    isDismissable={false}
                    showWarningIcon
                    data-testid="error-notice"
                  >
                    There's been a problem with your search. Please check your connection and try
                    again by refreshing the page. If the problem persists, contact support.
                  </ValidationError>
                </div>
              ) : (
                renderFoundOptions(getItemProps, highlightedIndex, inputValue)
              )}
            </div>
          )}
        </div>
      )}
    </Downshift>
  );
};

export default PortAutocomplete;
