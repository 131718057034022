import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';
import { Tab } from 'components/tab';
import './styles.scss';
import DragAndDropItem from 'components/drag-and-drop/drag-and-drop-item';

export const WorksheetTab = ({
  isActive,
  value,
  workbookId,
  id,
  isErrorState,
  sortOrderType,
  isDragging,
  index,
}) => {
  const classes = classNames('worksheet-tab', {
    'worksheet-tab--active': isActive,
  });
  const navigate = useNavigate();
  const url = `/workbook/${workbookId}/worksheet/${id}`;

  const handleMouseDown = (e) => {
    navigate(url);
  };

  return (
    <DragAndDropItem key={id} draggableId={id} index={index} onMouseDown={handleMouseDown}>
      <div style={{ display: 'flex' }}>
        <ReactTooltip className="ve-tooltip-default" id="tab-tooltip" noArrow />
        <Link
          className="worksheet-tab__wrapper"
          to={url}
          tabIndex="-1"
          style={{ pointerEvents: 'none' }}
        >
          <Tab
            isActive={isActive}
            className={classes}
            sortOrderType={sortOrderType}
            tabName={value}
            data-tooltip-content={value}
            data-tooltip-delay-show={250}
            data-tooltip-id="tab-tooltip"
            data-tooltip-place="bottom"
          >
            <TabContent value={value} />
          </Tab>
        </Link>
      </div>
    </DragAndDropItem>
  );
};

const TabContent = ({ hasErrored, value }) => <div className="worksheet-tab__content">{value}</div>;
