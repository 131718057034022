import React, { Fragment } from 'react';
import { useHorizontalScroll } from 'hooks/horizontal-scroll';

import { IconButton } from 'components/button';
import { ActionBarItem } from '../action-bar';
import DragAndDropContainer from 'components/drag-and-drop/drag-and-drop-container';

import './styles.scss';

const carouselDirections = { LEFT: 'LEFT', RIGHT: 'RIGHT' };

export const Carousel = ({
  canSlideLeft,
  canSlideRight,
  mouseWheelIncrement,
  childWidth,
  handleDragEnd,
  handleDragStart,
  children,
}) => {
  const containerRef = React.useRef();

  const { swipeLeft, canSwipeLeft, swipeRight, canSwipeRight } = useHorizontalScroll(containerRef, {
    mouseWheelIncrement: mouseWheelIncrement || 25,
    swipeIncrement: childWidth || 146,
    snapToIncrement: true,
  });

  const controlsAreRequired = canSwipeLeft || canSwipeRight;

  return (
    <Fragment>
      <ActionBarItem>
        <CarouselControl
          isControlRequired={controlsAreRequired}
          direction={carouselDirections.LEFT}
          disabled={!canSwipeLeft}
          onClick={swipeLeft}
        />
      </ActionBarItem>

      <div className="carousel-wrapper">
        <DragAndDropContainer
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          direction="horizontal"
        >
          <div className="carousel" ref={containerRef}>
            {children}
          </div>
        </DragAndDropContainer>
      </div>

      <ActionBarItem>
        <CarouselControl
          isControlRequired={controlsAreRequired}
          direction={carouselDirections.RIGHT}
          disabled={!canSwipeRight}
          onClick={swipeRight}
        />
      </ActionBarItem>
    </Fragment>
  );
};

function CarouselControl(props) {
  const icon =
    props.direction === carouselDirections.LEFT ? 'icon--chevron-left' : 'icon--chevron-right';

  return props.isControlRequired ? (
    <IconButton icon={icon} disabled={props.disabled} onClick={props.onClick} />
  ) : (
    <div className="carousel__control-spacer" />
  );
}
