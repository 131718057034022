import { createSelector } from 'reselect';
import { emptyGuid } from 'utilities/guid';
import { getActiveWorksheet } from 'common-selectors/get-active-worksheet';
import { getActiveWorkbook } from 'common-selectors/get-active-workbook';
import { immutableEmptyCalculation } from 'reducers/calculations';
import { sortArrayObjectsBy } from 'utilities/array-object-sort';
export const selector = createSelector(
  getActiveWorksheet,
  (state) =>
    Object.values(state.worksheetsById).filter((e) => e.workbookId === state.activeWorkbookId),
  (state) => state.calculationsByWorksheetId,
  (state) => state.worksheetComparison.viewMode,
  getActiveWorkbook,
  (state) => state.referenceData,
  (state) => state.bunkerPorts.bunkerPrices,
  (state) => state.comparisonTableSettings.columnsState,
  (state) => state.comparisonTableColumnsDefs.columnsDefs,
  (
    activeWorksheet,
    worksheets,
    calculationsByWorksheetId,
    viewMode,
    activeWorkbook,
    referenceData,
    bunkerPortPrices,
    columnsState,
    columnsDefs
  ) => {
    const worksheetsSortedForDisplay = sortArrayObjectsBy(
      worksheets,
      activeWorkbook.sortOrderType,
      'name',
      activeWorkbook.worksheetSortOrderByIds
    );

    const worksheetsCalculations = worksheetsSortedForDisplay.map((worksheet) => {
      const calc = calculationsByWorksheetId[worksheet.id];
      if (!calc && calculationsByWorksheetId) {
        return immutableEmptyCalculation;
      }
      return calc;
    });

    const worksheetsWithBunkerPorts = worksheetsSortedForDisplay.filter(
      doesWorksheetHaveBunkerPorts
    );
    const tableConfiguration = columnsDefs.map((option, index) => {
      return {
        columnId: columnsState[index]?.columnId,
        name: option?.name,
        parentId: columnsState[index]?.parentId,
        field: option?.field,
        hasDivider: option?.hasDivider,
        state: columnsState[index]?.state,
        expanded: false,
      };
    });

    return {
      worksheetsCalculations,
      viewMode,
      worksheets,
      activeWorkbook,
      activeWorksheet,
      referenceData,
      bunkerPorts: bunkerPortPrices,
      worksheetsWithBunkerPorts,
      columnsState,
      columnsDefs,
      tableConfiguration,
      worksheetsSortedForDisplay,
    };
  }
);

const doesWorksheetHaveBunkerPorts = (worksheet): Boolean => {
  if (!worksheet || !worksheet.bunkerExpenses || !worksheet.bunkerExpenses.bunkers) {
    return false;
  }
  if (worksheet.bunkerExpenses.bunkers.find((e) => e.key !== emptyGuid)) {
    return true;
  }
  return false;
};
