import React from 'react';
import { arcUrl } from 'config';
import './styles.scss';
import classNames from 'classnames';

const UserPhoto = ({ userName, className, ...props }) => (
  <img
    className={classNames('user-photo', className)}
    src={`${encodeURI(arcUrl)}/api/1/0/User/thumbnailimage/${encodeURIComponent(userName)}`}
    {...props}
    alt={`User ${userName}`}
  />
);

export default UserPhoto;
