import React, { Component } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { cdnUrl } from 'config';
import Downshift from 'downshift';
import numbro from 'numbro';
import { Vessel } from 'modules/vessel/vessel-detail/types';
import { isWet, MarketSegmentId } from 'constants/market-segments';
import { BalticVessel, VesselMenuItem } from './types';
import { debounceDefaults } from 'constants/defaults/debounce';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import classNames from 'classnames';

import './styles.scss';
import { getVesselsByFreeText } from 'api/clients/vessel';
import { isTanker } from 'constants/market-segments';

type Props = {
  marketSegmentId: MarketSegmentId,
  vessel: Vessel,
  onChange: Function,
  balticIndexVessels: Array<BalticVessel>,
  isMandatory?: boolean,
};

type State = {
  vessels: Array<VesselMenuItem>,
  vessel: Vessel,
};

class VesselAutoComplete extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { vessels: [], vessel: props.vessel };
    this.searchVessels = debounce(this.searchVessels, debounceDefaults.wait, {
      leading: debounceDefaults.leading,
      maxWait: debounceDefaults.maxWait,
    });
  }

  flagUrl = `${cdnUrl}/Data/PublicArtificats/flags/ALPHA3/16x11/`;
  highlightClass = 'vessel-autocomplete__menu-item--highlighted';
  cancelTokenSource: CancelTokenSource = null;
  cache: Object = {};

  emptyVessel: Vessel = {
    vesselId: 0,
    vesselName: '',
  };

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    return (
      this.props.vessel.vesselId !== nextProps.vessel.vesselId ||
      (this.props.vessel.vesselId === null && nextProps.vessel.vesselId === null) ||
      this.state !== nextState
    );
  }

  searchVessels = async (searchTerm: string): void => {
    if (this.cancelTokenSource !== null) {
      this.cancelTokenSource.cancel('Cancelled!');
    }

    if (!this.isSearchTermValid(searchTerm)) {
      this.clearVesselsInState();
      return;
    }

    const cachedVessels = this.cache[searchTerm];
    if (cachedVessels) {
      this.setVesselsInState(cachedVessels);
      return;
    }

    this.cancelTokenSource = axios.CancelToken.source();

    this.cleanupTasks.push(() => this.cancelTokenSource.cancel('cleanup'));

    try {
      const apiVessels = await getVesselsByFreeText({
        searchTerm: searchTerm,
        marketSegmentId: this.props.marketSegmentId,
        cancelToken: this.cancelTokenSource.token,
      });
      const vessels: Array<VesselMenuItem> = apiVessels.map(this.mapVesselDtoToMenuItem);
      this.cache[searchTerm] = vessels;
      this.setVesselsInState(vessels);
    } catch (err) {
      if (axios.isCancel(err)) {
        trackEvent(
          'VesselAutoComplete/searchVessels',
          'Worksheet Vessel Search or Autocomplete Cancelled',
          {
            searchTerm,
          },
          {},
          eventDestination.ANALYSIS
        );
        return;
      }
      if (err.message !== 'cleanup') {
        /* only prevent the state from being updated/cleared when the component 
        has been unmounted, it's not like the user is going to clear as they've 
        navigated away */
        this.clearVesselsInState();
      }
      // TODO #feedbackToUIForAutocompleteState
      throw err;
    }
  };

  mapVesselDtoToMenuItem = (apiVessel: VesselSearchResultItemDto): Array<VesselMenuItem> => {
    return {
      vesselId: apiVessel.vesselId,
      vesselName: apiVessel.displayName,
      imo: apiVessel.imo,
      flagCode: encodeURIComponent(apiVessel.flagCode),
      deadweight: numbro(apiVessel.deadweight || 0).format({
        thousandSeparated: true,
      }),
      buildYear: apiVessel.buildYear,
      isBalticVessel: false,
    };
  };

  isSearchTermValid(searchTerm: string): boolean {
    return (
      isNil(searchTerm) === false &&
      isEmpty(searchTerm) === false &&
      searchTerm !== this.props.vessel.vesselName
    );
  }

  onBlurHandler = (event: SyntheticFocusEvent<HTMLInputElement>, closeMenu: Function): void => {
    const val = event && event.target && event.target.value;

    if (isEmpty(val)) {
      event.nativeEvent.preventDownshiftDefault = true;
      this.onChangeHandler(this.emptyVessel);
      closeMenu();
    }
  };

  onFocusHandler = (event: SyntheticFocusEvent<HTMLInputElement>, openMenu: Function): void => {
    event.target.select();
    this.clearVesselsInState();

    if (isEmpty(event.target.value)) {
      openMenu();
    }
  };

  onChangeHandler = (item: VesselMenuItem): void => {
    if (this.props.onChange) {
      this.props.onChange(item);
    }
    this.clearVesselsInState();

    trackEvent(
      this.props.diagnosticId,
      'Worksheet Vessel Change',
      {
        eventSource: this.props.diagnosticId,
      },
      {},
      eventDestination.ANALYSIS
    );
  };

  onKeyDownHandler = (
    event: SyntheticKeyboardEvent<HTMLInputElement>,
    selectHighlightedItem: Function,
    isOpen: boolean
  ): void => {
    const keyCode = event?.key;

    if (isNil(keyCode) === false && keyCode === 'Tab') {
      selectHighlightedItem();
      this.clearVesselsInState();
    }
    if (isNil(keyCode) === false && keyCode === 'Escape' && !isOpen) {
      event.nativeEvent.preventDownshiftDefault = true;
    }
  };

  isEmptyValue = () => !this.props.vessel.vesselName;
  setVesselsInState(vessels) {
    this.setState({ vessels: vessels });
  }

  clearVesselsInState() {
    this.setVesselsInState([]);
  }

  shouldBeHighlighted(highlightedIndex: number, index: number) {
    return highlightedIndex === index;
  }

  itemToString = (item: Vessel | null) => {
    return isNil(item) || isNil(item.vesselName) ? '' : item.vesselName;
  };

  //TODO: Split this separate components and new files
  renderSearchVessels = (getItemProps, highlightedIndex) => {
    return (
      <div className="vessel-autocomplete__menu">
        {this.state.vessels.map((vessel, index) => (
          <div
            {...getItemProps({ item: vessel })}
            data-testid="vesselautocomplete-non-baltic-menu-item"
            key={vessel.vesselId}
            className={classNames(
              `vessel-autocomplete__menu-item
            ${this.shouldBeHighlighted(highlightedIndex, index) ? this.highlightClass : ''}`,
              {
                'tankers-intake': isTanker(this.props.marketSegmentId),
              }
            )}
          >
            <div
              className="vessel-autocomplete__menu-item--imo"
              data-testid="vesselautocomplete-non-baltic-menu-item-imo"
            >
              {vessel.imo}
            </div>
            <div className="vessel-autocomplete__menu-item--name">
              <div
                className="vessel-autocomplete__menu-item--name-inner"
                data-testid="vesselautocomplete-non-baltic-menu-item-name"
              >
                {vessel.vesselName}
              </div>
            </div>
            <div
              className="vessel-autocomplete__menu-item--deadweight"
              data-testid="vesselautocomplete-non-baltic-menu-item-deadweight"
            >
              {vessel.deadweight}
            </div>
            <div
              className="vessel-autocomplete__menu-item--flag"
              data-testid="vesselautocomplete-non-baltic-menu-item-flag"
            >
              {vessel.flagCode && (
                <img
                  alt={vessel.flagCode}
                  src={`${encodeURI(this.flagUrl)}${encodeURIComponent(vessel.flagCode)}.gif`}
                />
              )}
            </div>
            <div data-testid="vesselautocomplete-non-baltic-menu-item-yearofbuild">
              {vessel.buildYear}
            </div>
          </div>
        ))}
      </div>
    );
  };

  //TODO: Split this separate components and new files
  renderBalticIndexVessels = (getItemProps, highlightedIndex) => {
    return (
      <div className="vessel-autocomplete__menu">
        {this.props.balticIndexVessels.map((vessel, index) => (
          <div
            {...getItemProps({ item: vessel })}
            data-testid="vesselautocomplete-baltic-menu-item"
            key={vessel.vesselId}
            className={`vessel-autocomplete__menu-item
            ${this.shouldBeHighlighted(highlightedIndex, index) ? this.highlightClass : ''}`}
          >
            <div className="vessel-autocomplete__menu-item--name">
              <div
                className="vessel-autocomplete__menu-item--name-inner"
                data-testid="vesselautocomplete-baltic-menu-item-name"
              >
                {vessel.vesselName}
              </div>
            </div>
            <div
              className="vessel-autocomplete__menu-item--deadweight"
              data-testid="vesselautocomplete-baltic-menu-item-deadweight"
            >
              {vessel.deadweight}
            </div>
          </div>
        ))}
      </div>
    );
  };

  componentWillUnmount() {
    for (const cleanupTask of this.cleanupTasks) {
      cleanupTask();
    }
  }

  cleanupTasks: Array<() => void> = [];
  componentDidUpdate(prevProps: Props) {
    // Check if this.props.port has changed
    if (
      prevProps.vessel.vesselId !== this.props.vessel.vesselId ||
      prevProps.vessel.vesselName !== this.props.vessel.vesselName
    ) {
      // Update the state with the new port value
      this.setState({ vessel: this.props.vessel });
    }
  }
  render() {
    return (
      <Downshift
        onChange={this.onChangeHandler}
        onInputValueChange={this.searchVessels}
        selectedItem={this.state.vessel}
        itemToString={this.itemToString}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          highlightedIndex,
          inputValue,
          selectHighlightedItem,
          openMenu,
          closeMenu,
        }) => (
          <div className="vessel-autocomplete">
            <input
              {...getInputProps({
                placeholder: 'Enter vessel',
                onBlur: (event) => this.onBlurHandler(event, closeMenu),
                onFocus: (event) => this.onFocusHandler(event, openMenu),
                onKeyDown: (event) => this.onKeyDownHandler(event, selectHighlightedItem, isOpen),
              })}
              maxLength={160}
              className={classNames('vessel-autocomplete__input', {
                'vessel-autocomplete__input-isMandatory':
                  this.props.isMandatory && this.isEmptyValue(),
              })}
              data-testid={this.props['data-testid']}
              ref={this.props.inputElementRef}
            />
            {isOpen
              ? inputValue
                ? this.state.vessels.length > 0
                  ? this.renderSearchVessels(getItemProps, highlightedIndex)
                  : null
                : !isWet(
                      this.props.marketSegmentId
                    ) /* #HideDefunctFeaturesForWetCargo_BalticIndex - the 'Baltic Index' standard vessels are one of the features that doesn't currently exist for Wet cargo (the first sea/calc was only for Dry Cargo which has this feature). The feature probably could exist for Wet (based on "Baltic Exchange Clean Tanker Index") */ &&
                    this.props.balticIndexVessels.length > 0
                  ? this.renderBalticIndexVessels(getItemProps, highlightedIndex)
                  : null
              : null}
          </div>
        )}
      </Downshift>
    );
  }
}

export default VesselAutoComplete;
