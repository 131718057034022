import React, { Component, cloneElement, Fragment } from 'react';
import classNames from 'classnames';
import { IconButton } from 'components/button';
import DragAndDropContainer from 'components/drag-and-drop/drag-and-drop-container';
import './styles.scss';

export class Menu extends Component<Props> {
  constructor() {
    super();
    this.state = { isOpen: false };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
    if (typeof this.props.menuClosed === 'function') {
      this.props.menuClosed();
    }
  };

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }

    //Click is outside the menu element, and so close the menu.
    if (this.state.isOpen) {
      this.closeMenu();
    }
  };

  validateChildIsMenuItem = (child) => {
    const isChildMenuItemType = child.type === MenuItem;

    if (isChildMenuItemType === false) {
      console.error('All top level elements in Menu must be of type MenuItem');
    }

    return isChildMenuItemType;
  };

  appendCloseMenuBehaviour = (child, index) => {
    const wrappedOnClick = (event) => {
      if (child.props.onClick) {
        child.props.onClick(event);
      }

      this.closeMenu();
    };

    const clone = cloneElement(child, {
      key: index,
      onClick: wrappedOnClick,
    });

    return clone;
  };

  createMenuItems = (children) => {
    const menuItems = children
      .filter(this.validateChildIsMenuItem)
      .map(this.appendCloseMenuBehaviour);

    return menuItems;
  };

  onNodeMounted = (node) => (this.node = node);

  render() {
    const classes = classNames({ 'menu-open': this.state.isOpen }, this.props.className);

    let promotedItem = this.props.promotedItem ? (
      <Fragment>
        {this.props.promotedItem}
        <hr className="divider" />
      </Fragment>
    ) : null;

    return (
      <div ref={this.onNodeMounted}>
        <IconButton
          icon={this.props.icon || 'icon--more-vert'}
          className={classes}
          disabled={this.props.disabled}
          onClick={this.toggleMenu}
        />
        {this.state.isOpen && this.props.children.length > 0 ? (
          <div className="menu">
            <div className="menu-content">
              {promotedItem}
              <DragAndDropContainer
                className="menu-option-list"
                onDragStart={this.props.handleDragStart}
                onDragEnd={this.props.handleDragEnd}
              >
                {this.createMenuItems(this.props.children)}
              </DragAndDropContainer>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export const MenuItem = ({ className, children, ...restOfProps }) => {
  const classes = classNames('menu-item', className);

  return (
    <div className={classes} {...restOfProps}>
      {children}
    </div>
  );
};
