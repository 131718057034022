import { createSelector } from 'reselect';
import { getActiveWorkbook } from 'common-selectors/get-active-workbook';
import { sortArrayObjectsBy } from 'utilities/array-object-sort';

export const selector = createSelector(
  getActiveWorkbook,
  (state) =>
    Object.values(state.worksheetsById).filter(
      (worksheet) => worksheet.workbookId === state.activeWorkbookId
    ),
  (workbook, worksheets) => {
    const sortOrderType = workbook.sortOrderType;
    const worksheetsSortedForDisplay = sortArrayObjectsBy(
      worksheets,
      sortOrderType,
      'name',
      workbook.worksheetSortOrderByIds
    );

    return {
      workbook,
      sortOrderType,
      worksheetsSortedForDisplay,
    };
  }
);
