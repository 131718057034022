import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

interface DragAndDropContainerProps {
  children: React.ReactNode;
  onDragEnd?: () => void;
  onDragStart?: () => void;
  className?: string;
  direction?: string;
}

const DragAndDropContainer = ({
  children,
  onDragEnd,
  onDragStart,
  className,
  direction,
}: DragAndDropContainerProps) => {
  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="droppable" direction={direction}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={className}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropContainer;
