import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import flatten from 'lodash/flatten';
import classnames from 'classnames';
import Async from 'react-async';
import userContext from 'user-context';
import { Grid } from 'components/grid';
import { comparisonColumns } from './Columns';
import {
  isWorksheetLoadedStatus,
  WorksheetChangeStatus,
  isWorksheetFailureStatus,
  isWorksheetLoadingStatus,
  WorksheetStatus,
} from 'constants/enums/worksheet-status';
import EmitNotice from 'components/notices-emitter/emit-notice';
import ErrorBox from 'components/notice-box/error-box';
import {
  ViewModesItems,
  ViewModesEnum,
  mapToCharterTypes,
} from 'constants/enums/comparison-view-mode';
import { trackEvent, eventDestination } from 'diagnostics/calc-trackevents';
import { LinkCellRenderer, ProgressRenderer } from './cell-renderers';
import { selector } from './selector';
import { setColumnsDefs, switchViewMode } from 'actions/worksheet-comparison';
import { saveWorksheet } from 'middleware/worksheet-afterware/auto-save';
import { exportCalculationComparison } from 'actions/worksheet/export';
import buildWorksheetRequest from 'api/request-builders/worksheet-request-builder';
import { loadWorksheet, loadWorksheetsByIds } from 'actions/workbook';
import { fetchAllBunkerPricesAndSyncStore } from 'actions/bunker-ports';
import { DismissButton } from 'components/notice-box/dismissible';
import { formatNumber } from 'utilities/number';
import {
  getVesselRowProperties,
  getCostsRowProperties,
  getVoyageRateCostsRowProperties,
  getTimeCharterCostsRowProperties,
  getVoyageRowProperties,
  getArrivalAtFirstLoad,
  getVoyageEndDate,
  getCargoDetailsRowProperties,
  getVoyageRowCarbonConsumptionProperties,
} from './worksheet-computed-properties';
import { debounceDefaults } from 'constants/defaults/debounce';
import debounce from 'lodash/debounce';
import {
  isCalculationLoadingStatus,
  isCalculationFailureStatus,
  CalculationStatus,
} from 'constants/enums/calculation-status';
import { singleOrThrow, singleOrNullIfEmptyOrThrowIfMany } from 'utilities/iterable';
import './styles.scss';
import storePromiseOnCall from 'utilities/functions/store-promise-on-call';
import { runCalculateEstimateForWorksheetIds } from 'actions/worksheet-comparison';
import { refreshBunkersForWorksheets } from 'actions/bunker-ports';
import {
  BUNKER_PRICES_RETRIEVAL_FAILURE_NOTICE,
  COMPARISON_SUMMARY_EXPORT_FAILURE_NOTICE,
} from 'constants/enums/emit-notices';
import cloneDeep from 'lodash/cloneDeep';
import { round } from 'utilities/number';
import ComparisonTableUserControlsContainer from './comparisonTableUserControls';
import { setWorksheetApiVersion } from 'utilities/functions/set-worksheet-api-version';
import { setComparisonTableSettings } from 'actions/worksheet-comparison/comparison-table-settings';
import { speedAndConsumptionsModes } from 'api/clients/calculation';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

ModuleRegistry.registerModules([ClientSideRowModelModule]);
interface IComparisonTableProps {
  loadWorksheet: (worksheetId: UUID) => Promise<void>;
  loadWorksheetsByIds: (worksheetIds: UUID[], batchSize?: number) => Promise<void>;
  runCalculateEstimateForWorksheetIds: (worksheetIds: UUID[]) => Promise<void>;
  referenceData: IReferenceData;
  activeWorkbook: IWorkbookViewModel;
  activeWorksheet: IWorksheetViewModel;
  worksheetsCalculations: IWorksheetCalculations[];
  worksheets: IWorksheetViewModel[];
  setColumnDefs: () => void;
  setComparisonTableSettings: () => void;
}

function computeVesselRow(
  vessel,
  worksheet,
  calculation: ICalculationViewModel,
  activeWorksheetId,
  index,
  viewMode,
  isCheapestModeOn,
  { isLoading, isLoadFailure, isSaveFailure }
) {
  const grossTimeCharter = vessel ? vessel.grossTimeCharter : 0;
  const netTimeCharter = vessel ? vessel.netTimeCharter : 0;
  const cargo = worksheet.cargoes && singleOrThrow(worksheet.cargoes);
  const grossVoyageRate = cargo?.cargoRate?.grossVoyageRate ?? 0;
  const shouldBlank =
    calculation.calculationStatus !== CalculationStatus.LOADED ||
    worksheet.status !== WorksheetStatus.LOADED ||
    (grossTimeCharter === 0 && grossVoyageRate === 0) ||
    (viewMode.key === ViewModesEnum.TIME_CHARTER_TO_VOYAGE_RATE &&
      grossTimeCharter === 0 &&
      grossVoyageRate === 0 &&
      isCheapestModeOn) ||
    (viewMode.key === ViewModesEnum.VOYAGE_RATE_TO_TIME_CHARTER &&
      grossTimeCharter === 0 &&
      grossVoyageRate === 0 &&
      isCheapestModeOn);
  let totalsPerOutcomeTypeIsNull =
    shouldBlank ||
    !calculation ||
    !calculation.bunkersItineraryTotals ||
    !calculation.bunkersItineraryTotals.totalsPerOutcomeType;

  let overallBunkerCost = totalsPerOutcomeTypeIsNull
    ? null
    : calculation.bunkersItineraryTotals.totalsPerOutcomeType.carriedOverall.cost;
  let totalBunkerConsumptionCost = totalsPerOutcomeTypeIsNull
    ? null
    : calculation.bunkersItineraryTotals.totalsPerOutcomeType.consumed.cost;
  let lowestMaximumLiftInVoyage;
  if (!shouldBlank) {
    if (calculation.additionalResults) {
      let lowestMaximumLift;
      if (calculation.additionalResults.lowestMaximumLift) {
        lowestMaximumLift = calculation.additionalResults.lowestMaximumLift;
      }
      lowestMaximumLiftInVoyage = {
        lowestMaximumLift:
          lowestMaximumLift && lowestMaximumLift.maximumLift
            ? round(lowestMaximumLift.maximumLift)
            : null,
        errors: lowestMaximumLift ? lowestMaximumLift.errors : [],
      };
    }
  }
  return {
    isLoading,
    index: index + 1,
    isLoadFailure,
    isSaveFailure,
    id: `${worksheet.id}_${calculation && calculation.id}`,
    shouldBlank,
    worksheetName: worksheet.name,
    isActive: worksheet.id === activeWorksheetId,
    href: `#/workbook/${worksheet.workbookId}/worksheet/${worksheet.id}`,
    vesselName: (vessel && vessel.name) || '',
    grossVoyageRate: shouldBlank || grossVoyageRate === 0 ? null : grossVoyageRate,
    grossTimeCharter: shouldBlank || grossTimeCharter === 0 ? null : grossTimeCharter,
    netTimeCharter: shouldBlank || netTimeCharter === 0 ? null : netTimeCharter,
    calculatedGrossTimeCharter:
      shouldBlank || grossVoyageRate === 0
        ? null
        : calculation.voyageRate && calculation.voyageRate.timeCharterRateCalculatedGross,
    calculatedNetTimeCharter:
      shouldBlank || grossVoyageRate === 0
        ? null
        : calculation.voyageRate && calculation.voyageRate.timeCharterRateCalculatedNet,
    voyageProfitOrLossEstimate:
      shouldBlank || grossVoyageRate === 0
        ? null
        : calculation.voyageRate && calculation.voyageRate.voyageProfitOrLossEstimate,
    totalDaysVoyageProfitOrLossEstimate:
      shouldBlank || grossVoyageRate === 0
        ? null
        : calculation.voyageRate && calculation.voyageRate.totalDaysVoyageProfitOrLossEstimate,
    calculatedGrossVoyageRate:
      shouldBlank || grossTimeCharter === 0
        ? null
        : calculation.timeCharter && calculation.timeCharter.voyageRateCalculatedGross,
    ...getVesselRowProperties(worksheet, vessel, calculation, shouldBlank),
    ...getCostsRowProperties(calculation, shouldBlank),
    ...getTimeCharterCostsRowProperties(calculation, shouldBlank || grossTimeCharter === 0),
    ...getVoyageRateCostsRowProperties(calculation, shouldBlank || grossVoyageRate === 0),
    ...getVoyageRowProperties(
      worksheet,
      vessel,
      calculation,
      shouldBlank,
      mapToCharterTypes(viewMode.key)
    ),
    ...getCargoDetailsRowProperties(worksheet, calculation, shouldBlank),
    lowestMaximumLiftInVoyage:
      shouldBlank || !lowestMaximumLiftInVoyage ? null : lowestMaximumLiftInVoyage,

    overallBunkerCost: overallBunkerCost,
    totalBunkerConsumptionCost: totalBunkerConsumptionCost,
    ...getVoyageRowCarbonConsumptionProperties(calculation, shouldBlank),
    totalFreight:
      calculation && calculation.voyageRate && calculation.voyageRate.totalIncomeGross
        ? calculation.voyageRate.totalIncomeGross
        : 0,
  };
}

function computeWorksheetRows(
  worksheet,
  calculations: IWorksheetCalculations,
  activeWorksheetId,
  index,
  viewMode,
  isCheapestModeOn
) {
  const isLoadFailure = isWorksheetFailureStatus(worksheet.status);
  const isSaveFailure = worksheet.changeStatus === WorksheetChangeStatus.DIRTY;
  return calculations.calculationResults.map((calculationResult) => {
    const vessel = !calculationResult.vesselEntryId
      ? null /* `vesselEntryId` is `null` while we're loading the worksheet. TODO - consider refactoring such a situation out of the model (can just pass the worksheet & calculation promises to the grid and have the grid decide what to display while they're loading) */
      : worksheet.vessels &&
        singleOrNullIfEmptyOrThrowIfMany(
          /* returning 'null' if not found because it means, e.g. that the vessel has just been removed and the removal of the calculation is still pending */
          worksheet.vessels.filter((_) => _.entryId === calculationResult.vesselEntryId)
        );
    const isLoading =
      !isLoadFailure &&
      !isSaveFailure &&
      !isCalculationFailureStatus(calculationResult.status) &&
      (isWorksheetLoadingStatus(worksheet.status) ||
        isCalculationLoadingStatus(calculationResult.calculationStatus));

    return computeVesselRow(
      vessel,
      worksheet,
      calculationResult,
      activeWorksheetId,
      index,
      viewMode,
      isCheapestModeOn,
      { isLoading, isLoadFailure, isSaveFailure }
    );
  });
}

function computeRowDataFromWorksheets(
  worksheets: IWorksheetViewModel[],
  calculations: IWorksheetCalculations[],
  activeWorksheetId,
  viewMode,
  isCheapestModeOn
) {
  return flatten(
    worksheets.map((worksheet: IWorksheetViewModel, index) => {
      return computeWorksheetRows(
        worksheet,
        calculations[index],
        activeWorksheetId,
        index,
        viewMode,
        isCheapestModeOn
      );
    })
  );
}

const components = {
  linkRenderer: LinkCellRenderer,
  progressRenderer: ProgressRenderer,
};

const FetchBunkerPricesStatus = {
  PENDING: 1,
  SETTLED: 2,
};

export class ComparisonTable extends React.Component<IComparisonTableProps> {
  constructor(props) {
    super(props);
    this.setOfRowIdsToRedraw = new Set();

    this.state = {
      fetchBunkerPricesStatus: null,
      refreshBunkersPromise: null,
      exportComparisonSummaryPromise: null,
      isViewExpanded: false,
    };
    let currentViewMode = undefined;
    if (!props.viewMode) {
      if (
        this.props.activeWorksheet.vessels[0].grossTimeCharter &&
        !this.props.activeWorksheet.cargoes[0].cargoRate.grossVoyageRate
      ) {
        currentViewMode = ViewModesItems[1];
      } else {
        currentViewMode = ViewModesItems[0];
      }
      this.props.switchViewMode(currentViewMode);
    } else {
      currentViewMode = props.viewMode;
    }

    this.viewMode = currentViewMode;
    this.workbookId = props.activeWorkbook && props.activeWorkbook.id;
    this.workbookName = props.activeWorkbook && props.activeWorkbook.name;

    this.debouncedScroll = debounce(
      (event) => this.trackHorizontalScrollPosition(event),
      debounceDefaults.wait,
      {
        leading: false,
        maxWait: debounceDefaults.maxWait,
      }
    );
    this.columns = comparisonColumns(
      props.activeWorkbook.name,
      props.activeWorkbook.id,
      this.loadWorksheet,
      this.props.saveWorksheet,
      props.activeWorkbook.marketSegmentId
    );

    this.props.setColumnsDefs(this.columns);
  }

  trackHorizontalScrollPosition = (event) => {
    const horizontalScrollViewPort = document.getElementsByClassName(
      'ag-body-horizontal-scroll-viewport'
    )[3];
    if (horizontalScrollViewPort == null) {
      //The grid hasn't initialised yet, so we are not going to log this event and return early.
      return;
    }

    const horizontalScrollWidth =
      horizontalScrollViewPort.scrollWidth - horizontalScrollViewPort.clientWidth;

    const horizontalScrollPositionAsPercentage = formatNumber(
      (event.left / horizontalScrollWidth) * 100
    );

    trackEvent(
      'WorksheetComparison',
      'Worksheet Comparison Horizontal Scroll Position As Percentage',
      {
        horizontalScrollPositionAsPercentage: horizontalScrollPositionAsPercentage,
        userId: userContext.systemUserId,
      },
      {},
      eventDestination.ANALYSIS
    );
  };

  getRowClass = (row) =>
    classnames({
      'comparison-row': true,
      'comparison-row--active': row.data.isActive,
    });

  getRowStyle = (row) => {
    if (row.data.isActive) {
      return {
        lineHeight: '32px',
        backgroundColor: '#2196F3',
      };
    }
    return {
      lineHeight: '32px',
    };
  };
  switchViewMode = (viewMode) => {
    trackEvent(
      'WorksheetComparison',
      'Worksheet Comparison View Mode Change',
      {
        mode: viewMode.label,
      },
      {},
      eventDestination.ANALYSIS
    );
    this.props.switchViewMode(viewMode);
  };

  onSortChanged = (evt) => {
    if (evt.columnApi && evt.columnApi.getAllColumns) {
      const columns = evt.columnApi.getAllColumns();
      const sortColumn = columns.find((col) => col.sort);
      if (sortColumn) {
        trackEvent(
          'WorksheetComparison',
          'Worksheet Comparison Sort Changed',
          {
            direction: sortColumn.sort,
          },
          {},
          eventDestination.ANALYSIS
        );
      } else {
        trackEvent(
          'WorksheetComparison',
          'Worksheet Comparison Sort Reset',
          {},
          {},
          eventDestination.ANALYSIS
        );
      }
    }
  };

  onBunkerRefreshClick = async () => {
    await this.refreshBunkers();
  };

  onExpandClick = () => {
    this.setState({ isViewExpanded: !this.state.isViewExpanded });
  };

  refreshBunkers = storePromiseOnCall(
    async () => {
      if (this.props.worksheetsWithBunkerPorts.length === 0) {
        return;
      }

      this.setState({
        fetchBunkerPricesStatus: FetchBunkerPricesStatus.PENDING,
      });

      try {
        await this.props.refreshBunkersForWorksheets(this.props.worksheetsWithBunkerPorts);
      } catch (err) {
        throw err;
      } finally {
        this.setState({
          fetchBunkerPricesStatus: FetchBunkerPricesStatus.SETTLED,
        });
      }
    },
    /* storePromise: */ (promise) => this.setState({ refreshBunkersPromise: promise })
  );

  onExportComparisonSummaryClick = async () => {
    await this.exportComparisonSummary();
  };

  exportComparisonSummary = storePromiseOnCall(
    async () => {
      const workbookName = this.props.activeWorkbook.name;
      const worksheetCalculationSummaryPairs = [];

      for (let i = 0; i < this.props.worksheets.length; i++) {
        const worksheet = cloneDeep(this.props.worksheets[i]);
        setWorksheetApiVersion(worksheet);

        const calculationResults = this.props.worksheetsCalculations[i].calculationResults;

        const calculationSummaryByVesselEntryId = {};

        for (let j = 0; j < worksheet.vessels.length; j++) {
          const vessel = worksheet.vessels[j];
          const calculationResult = calculationResults[j];
          calculationSummaryByVesselEntryId[vessel.entryId] =
            enrichCalculationSummaryForComparisonExport(worksheet, vessel, calculationResult);
        }

        worksheetCalculationSummaryPairs.push({
          worksheet: buildWorksheetRequest(worksheet),
          calculationSummaryByVesselEntryId,
        });
      }

      try {
        await this.props.exportCalculationComparison(
          workbookName,
          worksheetCalculationSummaryPairs
        );
      } catch (err) {
        throw err;
      }

      function enrichCalculationSummaryForComparisonExport(worksheet, vessel, calculationSummary) {
        let prevailingIntake;
        if (calculationSummary && calculationSummary.additionalResults) {
          prevailingIntake = round(
            calculationSummary.additionalResults.lowestMaximumLift.maximumLift,
            0
          );
        }

        const arrivalAtFirstLoadPort = getArrivalAtFirstLoad(worksheet, calculationSummary, vessel);
        const voyageEndDate = getVoyageEndDate(vessel, calculationSummary);

        const shouldRedactCalculation =
          isCalculationFailureStatus(calculationSummary.calculationStatus) ||
          (vessel.grossTimeCharter === 0 && worksheet.cargoes[0].cargoRate.grossVoyageRate === 0);

        return {
          ...calculationSummary,
          prevailingIntake,
          arrivalAtFirstLoadPort,
          voyageEndDate,
          shouldRedactCalculation,
        };
      }
    },
    /* storePromise: */ (promise) => this.setState({ exportComparisonSummaryPromise: promise })
  );

  getGridOptions() {
    return {
      onBodyScroll: this.debouncedScroll,
      defaultColDef: {
        suppressMovable: true,
        sortable: true,
        resizable: false,
        tooltipValueGetter: ({ value }) => value,
      },
    };
  }

  async loadWorksheets() {
    const { worksheets } = this.props;
    await this.props.loadWorksheetsByIds(worksheets.map((e) => e.id));
  }

  async componentDidMount() {
    await this.loadWorksheets();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeWorksheet !== this.props.activeWorksheet && this.props.activeWorksheet) {
      trackEvent(
        'WorksheetComparison',
        'Worksheet Comparison Active Worksheet Change',
        {},
        {},
        eventDestination.ANALYSIS
      );
    }
  }

  loadWorksheet = (worksheetId) => this.props.loadWorksheet(worksheetId);

  getRowId = (params) => params.data.id;
  render() {
    const {
      viewMode,
      activeWorkbook,
      activeWorksheet,
      worksheets,
      worksheetsCalculations,
      columnsState,
    } = this.props;

    if (
      this.workbookId !== activeWorkbook.id ||
      this.viewMode !== viewMode ||
      this.workbookName !== activeWorkbook.name ||
      columnsState
    ) {
      this.viewMode = viewMode;

      this.workbookId = activeWorkbook.id;
      this.workbookName = activeWorkbook.name;
      this.columns = comparisonColumns(
        activeWorkbook.name,
        activeWorkbook.id,
        this.loadWorksheet,
        this.props.saveWorksheet,
        activeWorksheet.marketSegmentId,
        this.props.tableConfiguration
      );
    }
    const isCheapestModeOn =
      activeWorkbook.speedAndConsumptionsMode === speedAndConsumptionsModes.findCheapest;
    const data = computeRowDataFromWorksheets(
      this.props.worksheetsSortedForDisplay,
      worksheetsCalculations,
      activeWorksheet && activeWorksheet.id,
      viewMode,
      isCheapestModeOn
    );
    const areWorksheetsStillLoading = worksheets.some((w) => !isWorksheetLoadedStatus(w.status));

    const areCalculationsStillRunning = worksheetsCalculations.some((c) =>
      c.calculationResults.some((r) => r.calculationStatus === CalculationStatus.LOADING)
    );

    return (
      <div
        className={classnames('ve-comparison-table', {
          've-comparison-table-expanded': this.state.isViewExpanded,
        })}
      >
        <Async promise={this.state && this.state.refreshBunkersPromise}>
          <Async.Rejected>
            <EmitNotice
              type={BUNKER_PRICES_RETRIEVAL_FAILURE_NOTICE}
              worksheetId={activeWorksheet.id}
            >
              {({ dismiss, additionalProps }) => (
                <ErrorBox additionalprops={additionalProps}>
                  <h1>Failure</h1>
                  <div>Failed to update with the latest bunker prices.</div>
                  <DismissButton
                    dismiss={dismiss}
                    diagnosticId="ComparisonTable/DismissFailToUpdateBunkerPrices"
                  />
                </ErrorBox>
              )}
            </EmitNotice>
          </Async.Rejected>
        </Async>
        <Async promise={this.state && this.state.exportComparisonSummaryPromise}>
          <Async.Rejected>
            <EmitNotice
              type={COMPARISON_SUMMARY_EXPORT_FAILURE_NOTICE}
              worksheetId={activeWorksheet.id}
            >
              {({ dismiss, additionalProps }) => (
                <ErrorBox additionalprops={additionalProps}>
                  <h1>Failure</h1>
                  <div>Comparison summary export failed.</div>
                  <DismissButton
                    dismiss={dismiss}
                    diagnosticId="ComparisonTable/DismissFailToExport"
                  />
                </ErrorBox>
              )}
            </EmitNotice>
          </Async.Rejected>
        </Async>
        <ComparisonTableUserControlsContainer
          viewMode={viewMode}
          switchViewMode={this.switchViewMode}
          isComparisonTableExpanded={this.state.isViewExpanded}
          expandComparisonTable={this.onExpandClick}
          shouldDisableActions={
            areWorksheetsStillLoading ||
            areCalculationsStillRunning ||
            this.state.fetchBunkerPricesStatus === FetchBunkerPricesStatus.PENDING
          }
          onBunkerRefreshClick={this.onBunkerRefreshClick}
          onExportComparisonSummaryClick={this.onExportComparisonSummaryClick}
          runCalculateEstimateForWorksheetsInComparison={() =>
            this.props.runCalculateEstimateForWorksheetIds(worksheets.map((_) => _.id))
          }
        />
        <Grid
          getRowId={this.getRowId}
          immutableData={true}
          rowData={data}
          columnDefs={this.columns}
          headerHeight={32}
          onSortChanged={this.onSortChanged}
          suppressMultiSort={true}
          groupHeaderHeight={32}
          rowHeight={32}
          getRowClass={this.getRowClass}
          frameworkComponents={components}
          getRowStyle={this.getRowStyle}
          className="ve-comparison-table__grid"
          gridOptions={this.getGridOptions()}
          reactiveCustomComponents
        />
      </div>
    );
  }
}

const mapStateToProps = selector;

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      loadWorksheetsByIds,
      loadWorksheet,
      saveWorksheet,
      switchViewMode,
      fetchAllBunkerPricesAndSyncStore,
      refreshBunkersForWorksheets,
      exportCalculationComparison,
      runCalculateEstimateForWorksheetIds,
      setColumnsDefs,
      setComparisonTableSettings,
    },
    dispatch
  );
}

const ComparisonTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(ComparisonTable);

export default ComparisonTableContainer;
